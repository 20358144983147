import { ChatHistory, Question, QuestionResponse } from "../interface";

export default function getChatHistory(questions: Question[]): ChatHistory {
  return questions.map((question) => {
    return {
      query: question.query,
      timestamp: question.response.timestamp,
      answer: getAnswer(question.response),
    };
  });
}

export function getAnswer(response: QuestionResponse): string {
  let answer = `${response.content.header}\n\n'${response.content.tagline}\n\n`;
  response.content.paragraphs.forEach((p) => {
    if (p.header) {
      answer += `${p.header}\n\n`;
    }
    answer += `${p.text}\n\n`;
  });

  return answer;
}

type rowsType = {rows?:number}
export const TitleSkeleton = ({rows=2}:rowsType) => {
  return (
    <div role="status" className="mt-8 animate-pulse">
      <div className="h-[2.875rem] bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
      {rows>=2 && <div className="h-[2.875rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-4"></div>}
      {rows>=3 && <div className="h-[2.875rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-4"></div>}
      {rows>=4 && <div className="h-[2.875rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-4"></div>}
      <span className="sr-only">Loading...</span>
    </div>
  );
};

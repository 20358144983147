import { Button, Grid } from "../";
import { ArrowRightIcon, MovingSpinningCircles } from "../Icons";
import { Fragment, MutableRefObject, Ref, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { OneLineQuestionSkeleton } from "../Skeleton";
import classnames from "classnames";
import { QuestionSkeletonFullWidth } from "../Skeleton/QuestionSkeletonFullWidth";
import { useChat } from "../../context/chat-context";

export const QuestionBoxFullWidth = ({
  templateOrderIndex,
  followupQuestions = [],
  placeholder = "Ask a follow up or new question",
  style = "default",
  optionQuestion = "",
  backgroundColour = "white",
  isLoading,
  onSubmit,
  nextTemplate,
  sethideQuestion,
  customMargin
}: {
  templateOrderIndex: number;
  followupQuestions: string[];
  placeholder?: string;
  style: "default" | "darkTxt" | "lightTxt";
  optionQuestion?: string;
  isLoading?: boolean;
  onSubmit: (value: string, is_selected_follow_up?: boolean) => void;
  backgroundColour: string;
  nextTemplate?: MutableRefObject<HTMLDivElement>;
  sethideQuestion: React.Dispatch<React.SetStateAction<string>>;
  customMargin?: string;
}) => {
  const { questions, loading: streaming } = useChat();
  const [chosenFollowUpQuestion, setChosenFollowUpQuestion] = useState("");
  const [value, setValue] = useState("");

  const isLastQuestionBox = templateOrderIndex + 1 === questions.length;
  const showInput = isLastQuestionBox && !chosenFollowUpQuestion;

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(value);
    // if (sethideQuestion) { sethideQuestion(true) };
    setValue("");
  };

  useEffect(() => {   
    if (isLoading) {
      if (nextTemplate) {                                  
        nextTemplate.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })     
      }     
    } else {
      hideQuestion();
    }      
  }, [nextTemplate])

  const scrollNext = () => {
    if (nextTemplate) {                                  
      nextTemplate.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })     
    }  
  }
  const hideQuestion = async () => {
    await scrollNext();
    sethideQuestion("hide");         
  }

  return (
    <div
      id="question-component"
      className={`bg-${backgroundColour} rounded-t-[2.5rem] desktop:bg-transparent`}
    >
      <Grid
        customClasses={`bg-${backgroundColour} rounded-t-[2.5rem] gap-x-4 h-full relative question-component pt-[1.5rem] mobile:pb-[3.125rem] desktop:pb-[4rem] ${customMargin}`}
      >
        <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-9 flex tablet:flex-row mobile:flex-col tablet:pb-[1.5rem] desktop:pb-8 mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0">                    
          <div className="mobile:self-center desktop:-ml-6">
            <MovingSpinningCircles />                                                   
          </div>
          {isLoading ? (
            <OneLineQuestionSkeleton />
          ) : (
            <>
              {optionQuestion && (
                <p
                  id="optional-returned-question"
                  className={classnames("mobile:text-[1.125rem] tablet:text-[1.25rem] font-normal mobile:py-[1.688rem] tablet:py-0 tablet:ml-6 tablet:self-center", {
                    ["text-black"]: style === "default" || style === "darkTxt",
                    ["text-white"]: style === "lightTxt",
                  })}
                >
                  {optionQuestion}
                </p>
              )}
              {optionQuestion.length <= 0 && (
                <p
                  id="optional-returned-question"
                  className={classnames("mobile:text-[1.125rem] tablet:text-[1.25rem] font-normal mobile:py-[1.688rem] tablet:py-0 tablet:ml-6 tablet:self-center", {
                    ["text-black"]: style === "default" || style === "darkTxt",
                    ["text-white"]: style === "lightTxt",
                  })}
                >
                  You might be interested in:
                </p>
              )}
            </>
          )}
        </div>
        <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-10 desktop:col-end-24 mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0">
          <div>
            {isLoading ? (
              <div className="pb-[1.5rem]">
                <QuestionSkeletonFullWidth />
              </div>
            ) : (
              <>
                <div className="flex flex-col">
                  <div className="w-full">
                    {followupQuestions?.map((text, i) => {
                      return (
                        <Fragment key={i}>
                          {style === "default" ? (
                            <Button
                              style={
                                chosenFollowUpQuestion === text
                                  ? "brand"
                                  : "primary"
                              }
                              text={text}
                              onClick={() => {
                                // don't allow same question twice - may want a disabled style
                                if (
                                  streaming ||
                                  chosenFollowUpQuestion === text
                                ) {
                                  return;
                                }
                                setChosenFollowUpQuestion(text);
                                onSubmit(text, true);                                
                              }}
                              classes="mr-[0.875rem] mb-[0.75rem] mobile:text-left px-2"
                            />
                          ) : (
                            <Button
                              style={
                                chosenFollowUpQuestion === text
                                  ? "brand"
                                  : "primary"
                              }
                              text={text}
                              onClick={() => {
                                // don't allow same question twice - may want a disabled style
                                if (
                                  streaming ||
                                  chosenFollowUpQuestion === text
                                ) {
                                  return;
                                }
                                setChosenFollowUpQuestion(text);
                                onSubmit(text, true);                                
                              }}
                              classes="mr-[0.875rem] mb-[0.75rem] mobile:text-left px-2"
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
          {showInput && (
            <div className="pt-[0.625rem] pb-[1.5rem]">
            <label
              htmlFor="ask"
              className={classnames("sr-only", {
                ["text-black"]: style === "default",
                ["text-white"]: style === "lightTxt",
              })}
            >
              Ask a follow up or new question
            </label>
              <form className="relative flex gap-1" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="ask"
                  id="ask"
                  className={classnames(
                    "mobile:text-[1.125rem] tablet:text-[1.25rem] block w-full border-0 bg-transparent focus:outline-none leading-4",
                    {
                      ["text-black"]: style === "default",
                      ["text-white placeholder-white"]: style === "lightTxt",
                    }
                  )}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                />
                <div className="flex gap-2 justify-end bottom-1">
                  <button
                    id="question-btn"
                    onClick={handleSubmit}
                    disabled={isEmpty(value)}
                  >
                    <ArrowRightIcon />
                  </button>
                </div>
                <div
                  className="absolute inset-x-0 bottom-0 top-[42px] border-t border-neutral-400 peer-focus:border-t-2"
                  aria-hidden="true"
                />
              </form>
            </div>
          )}
        </div>
      </Grid>
    </div>
  );
};

import { createBrowserRouter } from "react-router-dom";
import { Root } from "./root";
import MainApp from "./pages/MainApp/MainApp";
import Streaming from "./pages/Streaming/Streaming";
import Initial from "./pages/Initial/Initial";
import VideoTest from "./pages/VideoTest";
import "./App.css";

export const router = createBrowserRouter([
  {
    path: "/templates",
    element: (
      <Root>
        <MainApp />
      </Root>
    ),
  },
  {
    path: "/streaming",
    element: (
      <Root>
        <Streaming />
      </Root>
    ),
  },
  {
    path: "/",
    element: (
      <Root>
        <Initial />
      </Root>
    ),
  },
  {
    path: "/entry",
    element: (
      <Root>
        <Initial />
      </Root>
    ),
  },
  {
    path: "/video-test",
    element: (
      <Root>
        <VideoTest />
      </Root>
    ),
  },
]);

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Cross,
  CircleStars,
  MovingSpinningCircles,
} from "../../components/Icons";
import { mockInitialQuestions } from "../../context/mock-data";
import classnames from "classnames";

export default function Interim({
  onSubmit,
}: {
  onSubmit: (value: string) => void;
}) {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [questionAsked, setQuestionAsked] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setExpanded(true);
    }, 500);
  }, []);

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setQuestionAsked(true);
    onSubmit(value);
    setValue("");
  };

  const handleClose = () => {
    window.parent.postMessage("CLOSE", "https://infographics.devsjr.com");
    navigate("/");
  };

  return (
    <div
      id="interim"
      className="transition-all duration-1000 backdrop-blur-sm bg-center h-screen w-full"
    >
      <div className="transition-all duration-1000 left-1/2 -translate-x-1/2 w-full h-full absolute bottom-0 bg-black/50 backdrop-blur-md">
        <div
          className={classnames(
            "flex content-center justify-center items-center bg-[#000000] opacity-80 z-50 text-white",
            {
              " transition-all duration-4000 w-[calc(100%-100px)] relative h-full desktop:min-h-screen desktop:mx-[2.5%] desktop:mt-[2.5%] rounded-t-[50px]":
                questionAsked,
              "h-[70%] desktop:w-[calc(100%-100px)] fixed inset-x-0 bottom-0 desktop:px-[122px] desktop:py-[85px] desktop:mx-[50px] flex-col p-[5%] rounded-t-[50px]":
                !questionAsked,
            }
          )}
        >
          {/* <div className="flex content-center justify-center items-center w-[calc(100%-100px)] bg-[#000000] opacity-80 text-white rounded-t-[50px] fixed inset-x-0 bottom-0 desktop:px-[122px] desktop:py-[85px] mx-[50px] flex-col p-[5%]"> */}
          <div
            className={classnames("absolute right-8 top-8", {
              "hidden transition-all duration-1000":
                !expanded && !questionAsked,
            })}
          >
            <span
              className={classnames("", {
                "hidden transition-all duration-1000":
                  !expanded || questionAsked,
              })}
              onClick={handleClose}
            >
              <Cross crossColour="white" />
            </span>
          </div>
          <div className="w-full">
            <form
              className={classnames("", {
                "hidden transition-all duration-1000":
                  !expanded && !questionAsked,
              })}
              onSubmit={handleSubmit}
            >
              <div
                className={classnames(
                  "flex border-b-[1px] py-4 mobile:mt-[40px]",
                  { "hidden transition-all duration-1000": questionAsked }
                )}
              >
                <input
                  className="flex outline-none w-full bg-transparent p4 desktop:text-2xl tablet:text-base"
                  placeholder="Ask me a question in any language..."
                  onChange={onChange}
                  value={value}
                />
                <button className="font-light desktop:text-2xl tablet:text-base">
                  enter
                </button>
              </div>
            </form>
            <div className={classnames("flex", { "flex-col": expanded })}>
              <div
                className={classnames(
                  "flex h-[110px] relative w-full justify-center items-center",
                  {
                    "hidden transition-all duration-1000": !expanded,
                    "pt-[30px] transition-all duration-1000":
                      expanded && !questionAsked,
                  }
                )}
              >
                <MovingSpinningCircles />
              </div>
              <div
                className={classnames("flex w-full h-[110px] justify-center", {
                  "hidden transition-all duration-1000":
                    !expanded || questionAsked,
                })}
              >
                <p className="ml-4 text-white h-[5px] desktop:text-2xl tablet:text-base">
                  "Or I have a few suggestions to get started:"
                </p>
              </div>
            </div>
          </div>
          <div
            className={classnames(
              "grid desktop:grid-cols-3 tablet:grid-cols-3 gap-x-4 w-full desktop:mt-[35px] mobile:mt-4",
              { hidden: !expanded || questionAsked }
            )}
          >
            {mockInitialQuestions.map((question) => (
              <div
                className="flex flex-row tablet:flex-col desktop:flex-col items-left mobile:items-center"
                key={question.replaceAll(" ", "")}
              >
                <div className="width-[150px] desktop:px-0 tablet:px-0 mobile:px-4 desktop:w-full tablet:w-full">
                  <CircleStars />
                </div>
                <div className="flex w-full">
                  <button
                    onClick={() => {
                      setQuestionAsked(true);
                      setTimeout(() => {
                        onSubmit(question);
                      }, 1000);
                    }}
                    className="mt-[18px] desktop:text-[1.063rem] tablet:text-sm mobile:text-[12px] text-left leading-[1.688rem]"
                  >
                    {question}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

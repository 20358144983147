import { useEffect, useState } from "react";
import { ArrowDownIcon, MovingSpinningCircles, StarsScroll } from "../../components/Icons";
import classNames from "classnames";
export const ButtonScrollDown = ({
  onClick,
  customClasses,
  versionColour,
}: {
  onClick?: () => void;
  customClasses: string;
  versionColour: string;
}) => {  
  const pageHeight = window.innerHeight;
  const scrollDown = () => {
    window.scrollBy({
      top: pageHeight,
      behavior: "smooth",
    });
  };

  return (
    <div id="button-scroll-down">        
      { versionColour === "white" && (
        <div className="flex">
        <button
          type="button"
          onClick={scrollDown}
          className={`${customClasses}`}
        >
          <ArrowDownIcon arrowColour="#000" />
        </button>        
        <span className="pl-8 w-48 text-base">Keep scrolling for more content</span>
      </div>
      )}
      { versionColour === "grey" && (
        <div className="flex">
          <button
            type="button"
            onClick={scrollDown}
            className={`${customClasses}`}
          >
            <ArrowDownIcon arrowColour="#000" />
          </button>        
          <span className="pl-8 w-48 text-base">Keep scrolling for more content</span>
        </div>
      )}
      { versionColour === "dark" && (        
        <div className="flex">
          <button
            type="button"
            onClick={scrollDown}
            className={`${customClasses}`}
          >
            <ArrowDownIcon arrowColour="#FFF" />
          </button>        
          <span className="pl-8 w-48 text-base text-white">Keep scrolling for more content</span>
        </div>
      )}   
      { versionColour === "image" && (                       
        <div className="flex">
          <button
            type="button"
            onClick={scrollDown}
            className={`${customClasses}`}
          >
            <ArrowDownIcon arrowColour="#FFF" />
          </button>        
          <span className="pl-8 w-48 text-base text-white">Keep scrolling for more content</span>
        </div>
      )}                       
    </div>
  );
};

import { ShortInform, LongInform, XThingsInform } from "../../templates/Inform";
import {
  ShortInspire,
  LongInspire,
  XThingsInspire,
} from "../../templates/Inspire";
import { MovingSpinningCircles } from "../../components/Icons";
import { ShortHero, LongHero, XThingsHero } from "../../templates/Hero";
import { useChat } from "../../context/chat-context";
import { Flavour, Template } from "../../interface";
import Interim from "../Interim/Interim";
import { Cross } from "../../components/Icons/Cross";
import { useNavigate } from "react-router-dom";
import { ContentRail, Fail } from "../../components";
import { Ref, useEffect, useRef, useState } from "react";

export default function Streaming() {
  const navigate = useNavigate();
  const { questions, askQuestion, loading, latestMetadata, flavours } =
    useChat();

  const handleClose = () => {
    window.parent.postMessage("CLOSE", "https://infographics.devsjr.com");
    navigate("/");
  };

  const [nextTemplate, setNextTemplate] = useState<Ref<HTMLDivElement>>(null);
  const heroLongRef = useRef<HTMLDivElement | null>(null);
  const heroShortRef = useRef<HTMLDivElement | null>(null);
  const heroXThingsRef = useRef<HTMLDivElement | null>(null);
  const informLongRef = useRef<HTMLDivElement | null>(null);
  const informShortRef = useRef<HTMLDivElement | null>(null);
  const informXThingsRef = useRef<HTMLDivElement | null>(null);
  const inspireLongRef = useRef<HTMLDivElement | null>(null);
  const inspireShortRef = useRef<HTMLDivElement | null>(null);
  const inspireXThingsRef = useRef<HTMLDivElement | null>(null);
  const failRef = useRef<HTMLDivElement | null>(null);
  

  return (
    <div className="h-full w-full block font-inter relative">
      {questions.length > 0 && (
        <div className="z-50 absolute mobile:right-4 mobile:top-6 tablet:right-8 tablet:top-8 desktop:right-[4.5%] desktop:top-[3%]">
          <span onClick={handleClose}>
            {questions[0].response.template === "long" && (<Cross crossColour="white" />)} 
             {questions[0].response.template === "short" && (<Cross crossColour="black" />)}
            {questions[0].response.template === "three_parts" && (<Cross crossColour="white" />)}
          </span>
        </div>
      )}
      {!questions.length && !loading && <Interim onSubmit={askQuestion} />}
      {!questions.length && loading && (
        <div className="relative h-full flex justify-center items-center min-h-screen desktop:mx-[2.5%] desktop:mt-[2.5%] bg-[#000000] opacity-90 rounded-t-[3.125rem]">
          <MovingSpinningCircles />
        </div>
      )}
      {questions.map((question, i) => {
        switch (flavours[i]) {
          case Flavour.fail: {
            return (
              <Fail
                key={i}
                failText={
                  question.response?.content.paragraphs[0]?.text as string
                }
                templateOrderIndex={i}
                loading={loading}
                askQuestion={askQuestion}
                ref={failRef}
                setNextTemplate={setNextTemplate}
                nextTemplate={nextTemplate}
              />
            );
          }
          case Flavour.hero: {
            switch (question.response.template) {
              case Template.short: {
                return (
                  <ShortHero
                    templateOrderIndex={i}
                    loading={loading}
                    response={question.response}
                    askQuestion={askQuestion}
                    key={i}
                    ref={heroShortRef}
                    setNextTemplate={setNextTemplate}
                    nextTemplate={nextTemplate}
                  />
                );
              }
              case Template.long: {
                return (
                  <LongHero
                    templateOrderIndex={i}
                    loading={loading}
                    response={question.response}
                    askQuestion={askQuestion}
                    key={i}
                    ref={heroLongRef}
                    setNextTemplate={setNextTemplate}
                    nextTemplate={nextTemplate}
                  />
                );
              }
              case Template.three_parts: {
                return (
                  <XThingsHero
                    templateOrderIndex={i}
                    loading={loading}
                    response={question.response!}
                    askQuestion={askQuestion}
                    key={i}
                    ref={heroXThingsRef}
                    setNextTemplate={setNextTemplate}
                    nextTemplate={nextTemplate}
                  />
                );
              }
              default: {
                return;
              }
            }
          }
          case Flavour.inform: {
            switch (question.response.template) {
              case Template.short: {
                return (
                  <ShortInform
                    templateOrderIndex={i}
                    loading={loading}
                    response={question.response}
                    askQuestion={askQuestion}
                    key={i}
                    ref={informShortRef}
                    setNextTemplate={setNextTemplate}
                    nextTemplate={nextTemplate}
                  />
                );
              }
              case Template.long: {
                return (
                  <LongInform
                    templateOrderIndex={i}
                    loading={loading}
                    response={question.response}
                    askQuestion={askQuestion}
                    key={i}
                    ref={informLongRef}
                    setNextTemplate={setNextTemplate}
                    nextTemplate={nextTemplate}
                  />
                );
              }
              case Template.three_parts: {
                return (
                  <XThingsInform
                    templateOrderIndex={i}
                    loading={loading}
                    response={question.response}
                    askQuestion={askQuestion}
                    key={i}
                    ref={informXThingsRef}
                    setNextTemplate={setNextTemplate}
                    nextTemplate={nextTemplate}
                  />
                );
              }
              default: {
                return;
              }
            }
          }
          case Flavour.inspire: {
            switch (question.response.template) {
              case Template.short: {
                return (
                  <ShortInspire
                    templateOrderIndex={i}
                    loading={loading}
                    response={question.response}
                    askQuestion={askQuestion}
                    key={i}
                    ref={inspireShortRef}
                    setNextTemplate={setNextTemplate}
                    nextTemplate={nextTemplate}
                  />
                );
              }
              case Template.long: {
                return (
                  <LongInspire
                    templateOrderIndex={i}
                    loading={loading}
                    response={question.response}
                    askQuestion={askQuestion}
                    key={i}
                    ref={inspireLongRef}
                    setNextTemplate={setNextTemplate}
                    nextTemplate={nextTemplate}
                  />
                );
              }
              case Template.three_parts: {
                return (
                  <XThingsInspire
                    templateOrderIndex={i}
                    loading={loading}
                    response={question.response}
                    askQuestion={askQuestion}
                    key={i}
                    ref={inspireXThingsRef}
                    setNextTemplate={setNextTemplate}
                    nextTemplate={nextTemplate}
                  />
                );
              }
              default: {
                return;
              }
            }
          }
        }
      })}
      {latestMetadata.length ? (
        <ContentRail metadata={latestMetadata} />
      ) : (
        <></>
      )}
    </div>
  );
}

import { MutableRefObject, Ref, forwardRef, useEffect, useState } from "react";
import { Grid } from "../../../components";
import { QuestionResponse } from "../../../interface";
import {
  TitleSkeleton,
  ParagraphSkeleton,
  OneLineQuestionSkeleton,
} from "../../../components/Skeleton";
import classnames from "classnames";
import { QuestionBoxFullWidth } from "../../../components/QuestionBox/QuestionBoxFullWidth";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";

export const XThingsInform = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate     
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query } = response;

    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [para3Done, setPara3Done] = useState(false);
    const [header1Done, setHeader1Done] = useState(false);
    const [header2Done, setHeader2Done] = useState(false);
    const [header3Done, setHeader3Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState("show");
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;

    useEffect(() => {
      console.log("xthingInform");
      setNextTemplate(ref);
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    return (
      <div ref={ref} id="xthings-inform" className="relative">
        {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
        <Grid
          customClasses={classnames(
            "gap-x-4 h-full relative rounded-t-[3.125rem] -mt-[2.5rem]",
            {
              ["bg-white"]: version === "white",
              ["bg-grey-200"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}
        >
          <div
            id="xthings-inform-tagline-container"
            className="mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:col-start-1 desktop:col-start-2 col-span-full mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <div
              id="xthings-inform-tagline"
              className="mobile:text-base desktop:text-xl font-medium uppercase"
            >
              {query}
            </div>
          </div>
          <div
            id="xthings-inform-content"
            className="mobile:col-start-1 desktop:col-start-2 desktop:col-span-8 mobile:col-span-full mobile:pt-[1.5rem] desktop:pt-[2.25rem] desktop:pr-8 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:flex desktop:flex-col desktop:justify-between desktop:pb-[8.375rem]"
          >
            {!content.header ? (
              <TitleSkeleton rows={1} />
            ) : (
              <h1
                id="xthings-inform-header"
                className="mobile:text-4xl desktop:text-5xl font-normal"
              >
                {content.header}
              </h1>
            )}
            <div className={classNames(
              "mobile:hidden desktop:block pt-16",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 desktop:visible"]: para3Done,  
                ["desktop:translate-y-full desktop:invisible desktop:h-0"]: !para3Done,                          
              }
            )}
            >
              {version === "white" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="white"
                />
              )}
              {version === "dark" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="dark"
                />
              )}
              {version === "grey" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="grey"
                />
              )}
            </div>            
          </div>
          <div
            id="xthings-inform-answer-content"
            className="mobile:col-start-1 desktop:col-start-11 mobile:col-span-full desktop:col-end-24 mobile:pt-[1.5rem] tablet:pt-[2.25rem] desktop:pt-[2.825rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 mobile:pb-[4.5rem] desktop:pb-[8.375rem]"
          >
            <div className="grid desktop:grid-cols-13 gap-x-4">
              <div className="mobile:col-start-1 mobile:col-span-full desktop:col-span-6 modile:row-start-1 ">
                {!content.paragraphs[0] ? (
                  <OneLineQuestionSkeleton />
                ) : (
                  <p className="text-2xl">
                    <TypewriterText
                      text={content.paragraphs[0].header}
                      type="strong"
                      isDone={header1Done}
                      setIsDone={setHeader1Done}
                    />
                  </p>
                )}
              </div>
              <div className="mobile:col-start-1 mobile:col-span-full desktop:col-span-6 mobile:row-start-2 desktop:row-start-2">
                {!content.paragraphs[0]?.text ? (
                  <ParagraphSkeleton />
                ) : (
                  header1Done && (
                    <TypewriterText
                      id="xthings-inform-paragraph-0"
                      className="text-base font-light desktop:col-span-6 mobile:pt-[0.75rem] desktop:pt-[1.375rem]"
                      text={content.paragraphs[0].text}
                      isDone={para1Done}
                      setIsDone={setPara1Done}
                    />
                  )
                )}
              </div>

              <div className="mobile:col-start-1 desktop:col-start-8 desktop:col-span-6 mobile:col-span-full mobile:row-start-3 desktop:row-start-1 mobile:pt-[1.375rem] desktop:pt-0">
                {!content.paragraphs[1] ? (
                  <OneLineQuestionSkeleton />
                ) : (
                  para1Done && (
                    <p className="text-2xl">
                      <TypewriterText
                        text={content.paragraphs[1].header}
                        type="strong"
                        isDone={header2Done}
                        setIsDone={setHeader2Done}
                      />
                    </p>
                  )
                )}
              </div>
              <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-8 desktop:col-span-6 mobile:row-start-4 desktop:row-start-2">
                {!content.paragraphs[1]?.text ? (
                  <ParagraphSkeleton />
                ) : (
                  header2Done && (
                    <TypewriterText
                      id="xthings-inform-paragraph-1"
                      className="text-base font-light desktop:col-span-6 mobile:pt-[0.75rem] desktop:pt-[1.375rem]"
                      text={content.paragraphs[1].text}
                      isDone={para2Done}
                      setIsDone={setPara2Done}
                    />
                  )
                )}
              </div>

              <div className="mobile:col-start-1 mobile:col-span-full desktop:col-span-6 mobile:row-start-5 desktop:row-start-3 mobile:pt-[1.375rem] desktop:pt-[2.25rem]">
                {content.paragraphs[2] && para2Done && (
                  <p className="text-2xl">
                    <TypewriterText
                      text={content.paragraphs[2].header}
                      type="strong"
                      isDone={header3Done}
                      setIsDone={setHeader3Done}
                    />
                  </p>
                )}
              </div>
              <div className="mobile:col-start-1 mobile:col-span-full desktop:col-span-6 mobile:row-start-6 desktop:row-start-4">
                {content.paragraphs[2]?.text && header3Done && (
                  <TypewriterText
                    id="xthings-inform-paragraph-2"
                    className="text-base font-light desktop:col-span-6 mobile:pt-[0.75rem] desktop:pt-[1.375rem]"
                    text={content.paragraphs[2].text}
                    isDone={para3Done}
                    setIsDone={setPara3Done}
                  />
                )}
              </div>
            </div>            
          </div>         
          <div
            id="question-container"              
            className={classNames(
              "col-span-full mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: para3Done,  
                ["desktop:translate-y-full mobile:invisible mobile:h-4 desktop:h-0"]: !para3Done,      
                ["mobile:invisible mobile:h-4 desktop:h-0"]: hideQuestion === "hide",      
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para3Done}
              onSubmit={askQuestion}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}    
              customMargin="!mx-0"
            />
          </div>
        </Grid>
      </div>
    );
  }
);

import { createContext, useContext, useState } from "react";
import { v4 } from "uuid";
import {
  Chunk,
  ContextMetadataItem,
  Flavour,
  Question,
  Template,
} from "../interface";
import stream from "../utils/stream";
import processChunk from "./process-chunk";
import calculateFlavours from "./calculate-flavours";
import getChatHistory from "./get-chat-history";

/**
 * The way this is currently used it may as well just be a hook. It would take very little effort to adjust it.
 */

export interface ChatContextProps {
  questions: Question[];
  flavours: Flavour[]; // these match the questions e.g. questions[3] should use flavours[3]
  askQuestion: (query: string) => void;
  loading: boolean;
  latestMetadata: ContextMetadataItem[];
}

const ChatContext = createContext<ChatContextProps>({
  questions: [],
  askQuestion: () => {},
  loading: false,
  latestMetadata: [],
  flavours: [],
});

const ChatProvider = (props: React.PropsWithChildren) => {
  const [conversationId, setConversationId] = useState("");
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);

  const latestMetadata =
    questions[questions.length - 1]?.response?.metadata || [];

  const askQuestion = (query: string) => {
    if (loading) return;
    setLoading(true);

    let conversation_id = conversationId;
    if (!conversation_id) {
      conversation_id = v4();
      setConversationId(conversation_id);
    }

    const seen_images = questions
      .map((q) => q.response?.content.imgURL)
      .filter((url) => url) as string[];

    let isNewQuestion = true;
    stream({
      query,
      chat_history: getChatHistory(questions),
      conversation_id,
      user_id: "test",
      is_selected_follow_up: false,
      seen_images,
      onChunk: (chunk: Chunk) => {
        setQuestions((prev) => {
          if (isNewQuestion) {
            isNewQuestion = false;
            return [
              ...prev,
              {
                query,
                response: processChunk(query, chunk, {
                  query,
                  template: Template.short, // will be overridden immediately
                  followUps: [],
                  userQuestion: "",
                  content: { paragraphs: [] },
                  metadata: [],
                  newTopic: questions.length === 0,
                  timestamp: "",
                }),
              },
            ];
          }

          const updatedState = [...prev];
          const activeQuestionIndex = prev.length - 1;
          updatedState[activeQuestionIndex] = {
            query,
            response: processChunk(query, chunk, {
              ...updatedState[activeQuestionIndex].response,
            }),
          };
          return updatedState;
        });
      },
      onDone: () => setLoading(false),
    });
  };

  return (
    <ChatContext.Provider
      value={{
        questions,
        askQuestion,
        loading,
        latestMetadata,
        flavours: calculateFlavours(questions),
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

const useChat = () => useContext(ChatContext);

export { ChatProvider, useChat };

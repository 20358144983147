export const CircleStars = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="12.65" stroke="#F2F2F2" strokeWidth="0.7" />
      <path
        d="M13.1885 7.01041C13.0506 6.6713 12.5588 6.6713 12.4209 7.01041L11.9224 8.23613C11.4286 9.45036 10.4433 10.4119 9.19909 10.8939L7.94314 11.3803C7.59566 11.5149 7.59566 11.9949 7.94314 12.1295L9.19909 12.616C10.4433 13.0979 11.4286 14.0595 11.9224 15.2737L12.4209 16.4994C12.5588 16.8385 13.0506 16.8385 13.1885 16.4994L13.687 15.2737C14.1808 14.0595 15.1661 13.0979 16.4103 12.616L17.6662 12.1295C18.0137 11.9949 18.0137 11.5149 17.6662 11.3803L16.4103 10.8939C15.1661 10.4119 14.1808 9.45036 13.687 8.23613L13.1885 7.01041Z"
        fill="#F2F2F2"
      />
      <path
        d="M9.85135 16.9555C9.77072 16.7573 9.48318 16.7573 9.40255 16.9555C9.11384 17.6654 8.53777 18.2276 7.81034 18.5094C7.60719 18.5881 7.60719 18.8687 7.81034 18.9474C8.53777 19.2292 9.11384 19.7914 9.40255 20.5013C9.48318 20.6995 9.77072 20.6995 9.85135 20.5013C10.1401 19.7914 10.7161 19.2292 11.4436 18.9474C11.6467 18.8687 11.6467 18.5881 11.4436 18.5094C10.7161 18.2276 10.1401 17.6654 9.85135 16.9555Z"
        fill="#F2F2F2"
      />
      <path
        d="M16.4759 14.8887C16.3952 14.6904 16.1077 14.6904 16.0271 14.8887C15.7384 15.5986 15.1623 16.1608 14.4349 16.4426C14.2317 16.5213 14.2317 16.8019 14.4349 16.8806C15.1623 17.1623 15.7383 17.7245 16.0271 18.4344C16.1077 18.6327 16.3952 18.6327 16.4759 18.4344C16.7646 17.7245 17.3406 17.1623 18.0681 16.8806C18.2712 16.8019 18.2712 16.5213 18.0681 16.4426C17.3406 16.1608 16.7646 15.5986 16.4759 14.8887Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

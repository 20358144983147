import { MutableRefObject, Ref, forwardRef, useEffect, useState } from "react";
import { Grid } from "../../../components";
import { QuestionResponse } from "../../../interface";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
} from "../../../components/Skeleton";
import classnames from "classnames";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import { QuestionBoxFullWidth } from "../../../components/QuestionBox/QuestionBoxFullWidth";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";

export const ShortInspire = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState("show");
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
        
    useEffect(() => {
      setNextTemplate(ref)
    }, [])

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    return (
      <div ref={ref} id="short-inspire" className="relative">
        <Grid
          customClasses={classnames("gap-x-4 h-full relative rounded-t-[3.125rem] -mt-[2.5rem]", {
            ["bg-white"]: version === "white",
            ["bg-grey-200"]: version === "grey",
            ["bg-grey-1000 text-white"]: version === "dark",
          })}
        >
          <div
            id="short-inspire-content"
            className="h-full mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-12 flex flex-col desktop:pb-[8.375rem] desktop:min-h-[650px]"
          >
            {" "}
            {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
            <div className="h-full">
              <div className="mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0">
                <div
                  id="short-inspire-tagline"
                  className="mobile:text-base desktop:text-xl font-medium uppercase"
                >
                  {query}
                </div>
                <div className="mobile:pt-[1.5rem] tablet:pt-[1.5rem] desktop:pt-[1.875rem]">
                  {!content.header ? (
                    <TitleSkeleton />
                  ) : (
                    <h1
                      id="short-inspire-header"
                      className="mobile:text-4xl desktop:text-5xl font-normal"
                    >
                      {content.header}
                    </h1>
                  )}
                </div>
                <div
                  id="short-inspire-paragraph-content-1"
                  className="mobile:pt-[2.25rem] desktop:pt-[2.75rem]"
                >
                  {!content.paragraphs[0] ? (
                    <>
                      <div className="mobile:block tablet:hidden desktop:block">
                        <ParagraphSkeleton />
                      </div>
                      <div className="mobile:hidden tablet:block desktop:hidden">
                        <ParagraphSkeleton lines="tablet" />
                      </div>
                    </>
                  ) : (
                    <TypewriterText
                      id="short-inspire-paragraph-1"
                      className="text-base font-light"
                      text={content.paragraphs[0].text}
                      isDone={para1Done}
                      setIsDone={setPara1Done}
                    />
                  )}
                </div>
                <div className={classNames(
                    "mobile:hidden desktop:block pt-16",
                    {
                      ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 desktop:visible"]: para1Done,  
                      ["desktop:translate-y-full desktop:invisible desktop:h-0"]: !para1Done,                          
                    }
                  )}
                  >
                  {version === "white" && (
                    <ButtonScrollDown
                      customClasses="relative"
                      versionColour="white"
                    />
                  )}
                  {version === "dark" && (
                    <ButtonScrollDown
                      customClasses="relative"
                      versionColour="dark"
                    />
                  )}
                  {version === "grey" && (
                    <ButtonScrollDown
                      customClasses="relative"
                      versionColour="grey"
                    />
                  )}
                </div>
              </div>

              <div className="pt-[1.375rem] desktop:pt-0">
                {!content.imgURL ? (
                  <ImageSkeleton display="mobile" />
                ) : (
                  <div
                    style={{ backgroundImage: `url('${content.imgURL}')` }}
                    className="w-full max-h-[450px] mobile:h-[500px] tablet:h-[450px] bg-no-repeat desktop:hidden bg-cover bg-center"
                  ></div>
                )}
              </div>
            </div>
          </div>

          {/* Desktop only image */}
          <div
            id="short-inspire-desktop-image-container"
            className="desktop:col-start-13 mobile:hidden desktop:block desktop:col-span-12 w-full h-full row-start-1 z-30"
          >
            {!content.imgURL ? (
              <ImageSkeleton display="desktop" />
            ) : (
              <>
                {!content.imgURL ? (
                  <div className="w-full h-screen max-h-full bg-gradient-to-r from-cyan-500 to-blue-500"></div>
                ) : (
                  <div
                    id="short-inspire-desktop-image"
                    style={{ backgroundImage: `url('${content.imgURL}')` }}
                    className={`w-full h-full min-h-full max-h-full bg-cover bg-center bg-no-repeat`}
                  ></div>
                )}
              </>
            )}
          </div>
          <div
            id="question-container"              
            className={classNames(
              "col-span-full desktop:!mx-0 mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: para1Done,  
                ["desktop:translate-y-full mobile:invisible mobile:h-4 desktop:h-0"]: !para1Done,      
                ["mobile:invisible mobile:h-4 desktop:h-0"]: hideQuestion === "hide",      
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para1Done}
              onSubmit={askQuestion}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}    
              customMargin="!mx-0"
            />
          </div>
        </Grid>
      </div>
    );
  }
);

import { MutableRefObject, Ref, forwardRef, useEffect, useState } from "react";
import { Grid } from "../../../components";
import { QuestionResponse } from "../../../interface";
import { ParagraphSkeleton, ImageSkeleton, TitleSkeleton } from "../../../components/Skeleton";
import classnames from "classnames";
import { QuestionBoxFullWidth } from "../../../components/QuestionBox/QuestionBoxFullWidth";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";

export const ShortInform = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState("show");
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;

    useEffect(() => {
      console.log("shortInform");
      setNextTemplate(ref);
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    return (
      <div ref={ref} id="short-inform" className="relative">
        {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
        <Grid
          customClasses={classnames(
            "gap-x-4 h-full relative rounded-t-[3.125rem] -mt-[2.5rem]",
            {
              ["bg-white"]: version === "white",
              ["bg-grey-200"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}
        >
          <div
            id="short-inform-tagline-container"
            className="mobile:pt-[2.625rem] tablet:pt-[2.625rem] mobile:pb-[2.25rem] tablet:pb-[1.5rem] desktop:pt-[5.25rem] desktop:pb-[2.25rem] desktop:col-start-2 col-span-full mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <div
              id="short-inform-tagline"
              className="mobile:text-base desktop:text-xl font-medium uppercase"
            >
              {query}
            </div>
            {!content.header ? (
              <TitleSkeleton rows={1} />
            ) : (
              <h1
                id="short-inform-header"
                className="mobile:pt-[2.25rem] desktop:pt-[2.25rem] mobile:text-4xl desktop:text-5xl font-normal"
              >
                {content.header}
              </h1>
            )}
          </div>

          <div
            id="short-inform-header-content"
            className="col-start-1 desktop:col-span-7 mobile:col-span-full desktop:min-h-[260px] h-full"
          >
            <div
              className={classnames(
                "flex flex-col h-full w-full desktop:px-20 mobile:py-[1.5rem] desktop:py-[1.75rem] mobile:px-[1.375rem] tablet:px-[2.75rem]",
                {
                  ["bg-grey-200 text-black"]: version === "white",
                  ["bg-white text-black"]:
                    version === "grey" || version === "dark",
                }
              )}
            >
              {!content.paragraphs[0] ? (
                <ParagraphSkeleton />
              ) : (
                <TypewriterText
                  id="short-inform-paragraph-1"
                  className="text-xl font-light"
                  text={content.paragraphs[0].text}
                  isDone={para1Done}
                  setIsDone={setPara1Done}
                />
              )}
            </div>
          </div>
          <div
            id="short-inform-image-content"
            className="mobile:col-start-1 desktop:col-start-8 w-full col-span-full desktop:min-h-[260px] h-full"
          >
            <div className="mobile:col-start-1 w-full desktop:col-start-1 col-span-full desktop:col-end-13 desktop:row-start-1 h-full mobile:pb-[4.5rem] desktop:pb-0">
              {!content.imgURL ? (
                <ImageSkeleton display="short-inform" />
              ) : (
                <>
                  {!content.imgURL ? (
                    <div className="w-full desktop:min-h-[260px] h-full max-h-full bg-gradient-to-r from-cyan-500 to-blue-500"></div>
                  ) : (
                    <div className="relative w-full h-full">
                      <div
                        style={{ backgroundImage: `url('${content.imgURL}')` }}
                        className={`w-full mobile:min-h-[175px] tablet:min-h-[260px] h-full max-h-full bg-cover bg-center bg-no-repeat`}
                      ></div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={classNames(
            "mobile:hidden desktop:block desktop:col-start-2 desktop:pt-16 desktop:col-span-7 mobile:pb-[4.5rem] desktop:pb-[8.375rem]",
            {
              ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 desktop:visible"]: para1Done,  
              ["desktop:translate-y-full desktop:invisible desktop:h-0"]: !para1Done,                          
            }
          )}
          >
            {version === "white" && (
              <ButtonScrollDown
                customClasses="relative"
                versionColour="white"
              />
            )}
            {version === "dark" && (
              <ButtonScrollDown customClasses="relative" versionColour="dark" />
            )}
            {version === "grey" && (
              <ButtonScrollDown customClasses="relative" versionColour="grey" />
            )}
          </div>          
          <div
            id="question-container"              
            className={classNames(
              "col-span-full mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: para1Done,  
                ["desktop:translate-y-full mobile:invisible mobile:h-4 desktop:h-0"]: !para1Done,      
                ["mobile:invisible mobile:h-4 desktop:h-0"]: hideQuestion === "hide",      
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para1Done}
              onSubmit={askQuestion}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}    
              customMargin="!mx-0"
            />
          </div>
        </Grid>
      </div>
    );
  }
);

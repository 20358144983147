import clone from "lodash.clonedeep";
import {
  Chunk,
  ChunkType,
  ChatChunk,
  FollowUpsChunk,
  UserQuestionChunk,
  QuestionResponse,
  Template,
} from "../interface";

const DELIMITER = "†";
let three_parts_status = "header";

export default function processChunk(
  question: string,
  chunk: Chunk,
  _response: QuestionResponse
): QuestionResponse {
  // we do this to avoid any weird issues with React & mutated state
  let response = clone(_response);

  switch (chunk.chunk_type) {
    case ChunkType.header: {
      console.log("NEW QUESTION RESPONSE:", {
        question,
        template: chunk.data.decide_template,
        image: chunk.data.image_data.src,
        assets: chunk.data.assets_data,
        headerChunk: chunk,
        continuity: chunk.data.check_continuity,
        context: chunk.data.check_context,
        safety: chunk.data.check_safety,
      });
      // this will only ever be preset to true when it's the first question
      if (!response.newTopic) {
        response.newTopic =
          chunk.data.check_continuity.startsWith("not_related");
      }

      response.template = chunk.data.decide_template.startsWith(
        "content_filter_triggered"
      )
        ? Template.content_filter_triggered
        : chunk.data.decide_template;

      let headlines = chunk.data.headlines.split("|");

      // catch funky delimiters
      if (headlines.length === 1) {
        headlines = chunk.data.headlines.split("\n\n");
        if (headlines.length === 2) {
          console.log(`CAUGHT HEADLINES DELIMITER:`, chunk.data.headlines);
        }
      }
      if (headlines.length === 1) {
        headlines = chunk.data.headlines.split("' ");
        if (headlines.length === 2) {
          console.log(`CAUGHT HEADLINES DELIMITER:`, chunk.data.headlines);
        }
      }
      if (headlines.length === 1) {
        headlines = chunk.data.headlines.split(" '");
        if (headlines.length === 2) {
          console.log(`CAUGHT HEADLINES DELIMITER:`, chunk.data.headlines);
        }
      }

      if (headlines.length === 2) {
        response.content.header = headlines[0];
        response.content.tagline = headlines[1];
      } else {
        console.log(`FAILED HEADLINES DELIMITER:`, chunk.data.headlines);
        response.content.header = headlines[0];
        // prevents skeleton
        response.content.tagline = " ";
      }

      // remove stray delimiters
      response.content.header = response.content.header.replaceAll(
        DELIMITER,
        ""
      );
      response.content.tagline = response.content.tagline.replaceAll(
        DELIMITER,
        ""
      );

      response.timestamp = chunk.timestamp;
      response.metadata = chunk.data.context_metadata;
      response.assets = chunk.data.assets_data;
      response.content.imgURL =
        chunk.data.image_data?.src ||
        chunk.data.assets_data?.images?.landscape?.url ||
        chunk.data.assets_data?.images?.square?.url ||
        chunk.data.assets_data?.images?.portrait[0]?.url;

      window.parent.postMessage(
        ["SET_MESSAGE_ID", chunk.message_id],
        "https://infographics.devsjr.com"
      );
      break;
    }
    case ChunkType.chat_content: {
      const text = (chunk as ChatChunk).data;

      if (response.template === Template.three_parts) {
        if (!response.content.paragraphs.length) {
          response.content.paragraphs.push({ text: "", header: text });
        } else if (three_parts_status === "header" && text.includes("|")) {
          three_parts_status = "text";
        } else if (text.includes(DELIMITER)) {
          three_parts_status = "header";
          response.content.paragraphs.push({ text: "", header: "" });
        } else {
          if (three_parts_status === "header") {
            response.content.paragraphs[
              response.content.paragraphs.length - 1
            ] = {
              header:
                response.content.paragraphs[
                  response.content.paragraphs.length - 1
                ].header + text,
              text: "",
            };
          } else {
            response.content.paragraphs[
              response.content.paragraphs.length - 1
            ] = {
              header:
                response.content.paragraphs[
                  response.content.paragraphs.length - 1
                ].header,
              text:
                response.content.paragraphs[
                  response.content.paragraphs.length - 1
                ].text + text,
            };
          }
        }
      } else {
        if (!response.content.paragraphs.length) {
          response.content.paragraphs.push({ text });
        } else if (text.replaceAll(" ", "") === DELIMITER) {
          response.content.paragraphs.push({ text: "" });
        } else {
          response.content.paragraphs[response.content.paragraphs.length - 1] =
            {
              text:
                response.content.paragraphs[
                  response.content.paragraphs.length - 1
                ].text + text,
            };
        }
      }
      break;
    }
    case ChunkType.follow_ups: {
      response.followUps = (chunk as FollowUpsChunk).data;
      break;
    }
    case ChunkType.user_question: {
      response.userQuestion = (chunk as UserQuestionChunk).data;
      break;
    }
    case ChunkType.chat_finish: {
      three_parts_status = "header";
      response = cleanUp(response);
      console.log("QUESTION_RESPONSE_FINISH", {
        response: response,
      });
      break;
    }
  }

  return response;
}

function cleanUp(_response: QuestionResponse) {
  // we do this to avoid any weird issues with React & mutated state
  let response = clone(_response);

  // due to trailing delimiters we can end up with extra empt paragraphs in the array, they don't hurt anything except the logging below, so just cleaning up for cleaner logs
  if (response.template === Template.short) {
    response.content.paragraphs.splice(1);
  } else if (response.template === Template.long) {
    response.content.paragraphs.splice(2);
  } else if (response.template === Template.three_parts) {
    response.content.paragraphs.splice(3);
  }

  // ensure no falsy values in existing data
  response.content.paragraphs.forEach((p, i) => {
    if (response.template === Template.three_parts) {
      if (!p.header) {
        console.log("CAUGHT MISSING PARAGRAPH HEADER");
        response.content.paragraphs[i].header = " ";
      }
    }
    if (!p.text) {
      console.log("CAUGHT MISSING PARAGRAPH TEXT");
      response.content.paragraphs[i].text = " ";
    }
  });

  // push missing paragraphs
  if (response.template === Template.short) {
    while (response.content.paragraphs.length < 1) {
      console.log("CAUGHT MISSING PARAGRAPH");
      response.content.paragraphs.push({ text: " " });
    }
  }

  if (response.template === Template.long) {
    while (response.content.paragraphs.length < 2) {
      console.log("CAUGHT MISSING PARAGRAPH");
      response.content.paragraphs.push({ text: " " });
    }
  }

  if (response.template === Template.three_parts) {
    while (response.content.paragraphs.length < 3) {
      console.log("CAUGHT MISSING PARAGRAPH");
      response.content.paragraphs.push({ header: " ", text: " " });
    }
  }

  return response;
}

import { QuestionResponse, Template } from "../interface";

export const mockInitialQuestions = [
  "How is WPP leading in AI innovation?",
  "What is WPP's commitment to net zero?",
  "How is WPP building a better future for our communities?",
];

const mockQuestionResponse: {
  [template: string]: QuestionResponse;
} = {
  Single: {
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/hero-short.png",
      paragraphs: [
        {
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa. Non sint nostrud enim aute adipisicing cillum minim fugiat deserunt anim excepteur consequat qui.",
        },
      ],
    },
  },
  ShortHero: {
    assets: {
      video: [
        {
          asset_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
          url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
          metadata: {},
          relevance_score: 10.391244,
        },
      ],
      pdf: [
        {
          asset_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
          url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
          metadata: {},
          relevance_score: 10.391244,
        },
      ],
    },
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "video",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "pdf",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 10.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/hero-short.png",
      paragraphs: [
        {
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa. Non sint nostrud enim aute adipisicing cillum minim fugiat deserunt anim excepteur consequat qui.",
        },
      ],
    },
  },
  LongHero: {
    assets: {
      video: [
        {
          asset_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
          url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
          metadata: {},
          relevance_score: 10.391244,
        },
      ],
      pdf: [
        {
          asset_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
          url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
          metadata: {},
          relevance_score: 10.391244,
        },
      ],
    },
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/hero-short.png",
      paragraphs: [
        {
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa. Non sint nostrud enim aute adipisicing cillum minim fugiat deserunt anim excepteur consequat qui.",
        },
        {
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
        {
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
      ],
    },
  },
  XThingsHero: {
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/hero-short.png",
      paragraphs: [
        {
          header: "Nulla aliquip ut duis",
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa.",
        },
        {
          header: "Consequat culpa duis id reprehenderit",
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis.",
        },
        {
          header: "Duis veniam elit est",
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis.",
        },
      ],
    },
  },
  ShortInform: {
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/Shapes.png",
      paragraphs: [
        {
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa. Non sint nostrud enim aute adipisicing cillum minim fugiat deserunt anim excepteur consequat qui.",
        },
      ],
    },
  },
  LongInform: {
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/hero-short.png",
      paragraphs: [
        {
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa. Non sint nostrud enim aute adipisicing cillum minim fugiat deserunt anim excepteur consequat qui.",
        },
        {
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
        {
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
      ],
    },
  },
  XThingsInform: {
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/hero-short.png",
      paragraphs: [
        {
          header: "Nulla aliquip ut duis duis veniam elit est",
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa. Non sint nostrud enim aute adipisicing cillum minim fugiat deserunt anim excepteur consequat qui.",
        },
        {
          header:
            "Consequat culpa duis id reprehenderit incididunt esse eiusmod",
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
        {
          header: "Nulla aliquip ut duis duis veniam elit est",
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
      ],
    },
  },
  ShortInspire: {
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/background-example.png",
      paragraphs: [
        {
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa. Non sint nostrud enim aute adipisicing cillum minim fugiat deserunt anim excepteur consequat qui.",
        },
      ],
    },
  },
  LongInspire: {
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/WPPImage.png",
      paragraphs: [
        {
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa. Non sint nostrud enim aute adipisicing cillum minim fugiat deserunt anim excepteur consequat qui.",
        },
        {
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
        {
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
      ],
    },
  },
  XThingsInspire: {
    metadata: [
      {
        doc_id: "88ec6190-ce9b-4f94-9930-66708908c00f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/time-of-my-life-at-sxsw",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2019/04/SXSW_R14video-1024x592.gif",
        page_title: "Time of My Life at SXSW - Group SJR",
        relevance: 10.391244,
      },
      {
        doc_id: "5fe74920-59e4-4b01-a213-4a2ae2e0871f",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.95309,
      },
      {
        doc_id: "ea19c585-0f4b-44bf-9e0a-e32058cfae85",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/values",
        thumbnail:
          "https://groupsjrstg.demosjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "SJR",
        relevance: 8.9433365,
      },
      {
        doc_id: "231c6e2c-bb2c-4c3b-a13e-2c37fe932098",
        type: "html",
        page_url: "https://groupsjrstg.demosjr.com/connect",
        thumbnail:
          "https://www.groupsjr.com/wp-content/uploads/2022/11/Desktop-WP-Dynamic-1.png",
        page_title: "Connect - Group SJR",
        relevance: 7.7555704,
      },
    ],
    query: "Who are SJR?",
    template: Template.long, // this will be ignored in MainApp.jsx
    userQuestion:
      "Are you interested in learning how WPP's award-winning strategies and sustainable practices can elevate your brand's market impact?",
    followUps: [
      "Where is SJR located?",
      "Who is the CEO of SJR",
      "What industries does SJR work with?",
    ],
    content: {
      header: "Laboris cupidatat cillum aute",
      tagline: "Non sint nostrud enim aute adipisicing cillum",
      imgURL: "/background-example.png",
      paragraphs: [
        {
          header: "Nulla aliquip ut duis duis veniam elit est",
          text: "Enim voluptate fugiat ipsum id aliquip sit nostrud reprehenderit reprehenderit. Ex ullamco ullamco reprehenderit mollit minim cupidatat fugiat quis culpa. Non sint nostrud enim aute adipisicing cillum minim fugiat deserunt anim excepteur consequat qui.",
        },
        {
          header:
            "Consequat culpa duis id reprehenderit incididunt esse eiusmod",
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
        {
          header: "Nulla aliquip ut duis duis veniam elit est",
          text: "Exercitation aute occaecat consectetur est aliquip velit cupidatat et quis. Sit ut et incididunt magna ut veniam non non excepteur amet. Duis officia culpa duis excepteur aliquip voluptate.",
        },
      ],
    },
  },
};

export default mockQuestionResponse;

import { Grid, QuestionBoxFullWidth } from "../../../components";
import { TitleSkeleton, ParagraphSkeleton } from "../../../components/Skeleton";
import { MutableRefObject, Ref, forwardRef, useEffect, useState } from "react";
import { QuestionResponse } from "../../../interface";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";

export const XThingsHero = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const { content, followUps, userQuestion, query } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [para3Done, setPara3Done] = useState(false);
    const [header1Done, setHeader1Done] = useState(false);
    const [header2Done, setHeader2Done] = useState(false);
    const [header3Done, setHeader3Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState("show");
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
        
    useEffect(() => {
      setNextTemplate(ref)
    }, [])

    return (
      <div id="xthings-hero" ref={ref} className={classNames(
        "relative rounded-t-[3.125rem]",
        {
          ["-mt-[2.5rem]"]: templateOrderIndex !== 0,   
          ["desktop:mt-[2.5%]"]: templateOrderIndex === 0,                   
        }
      )}>
        <div
          className="h-full desktop:mx-[2.5%] bg-no-repeat bg-center bg-cover relative rounded-t-[3.125rem]"
          style={{ backgroundImage: `url('${content.imgURL}')` }}
        > 
        <div className="bg-black/50 h-full desktop:min-h-full rounded-t-[3.125rem]">
          <Grid customClasses="mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 !mx-0 gap-x-4">
            <div className="desktop:mt-[84px] mt-[42px] mobile:col-start-1 desktop:col-start-2 col-span-full rounded-t-[3.125rem]">
              <div className="mobile:text-base desktop:text-xl text-white text-base font-medium">
                {query}
              </div>
              <div className="desktop:mt-[34px] mt-[24px]">
                {!content.header ? (
                  <TitleSkeleton rows={1} />
                ) : (
                  <h1 className="mobile:text-4xl tablet:text-5xl text-white text-5xl font-normal">
                    {content.header}
                  </h1>
                )}
              </div>
            </div>

            <div className="desktop:flex mobile:mt-[22px] desktop:mt-[34px] desktop:space-x-[50px] mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-24 justify-between">
              <div className="mobile:mb-[22px] mobile:p-[44px] tablet:px-[116px] tablet:py-[64px] desktop:p-[44px] rounded-lg mobile:min-h-[200px] desktop:min-h-[322px] desktop:w-[450px] bg-grey-200/95 flex flex-col grow items-start ">
                {!content.paragraphs[0] ? (
                  <TitleSkeleton />
                ) : (
                  <h1 className="text-2xl font-normal desktop:min-h-20">
                    <TypewriterText
                      text={content.paragraphs[0].header}
                      type="strong"
                      isDone={header1Done}
                      setIsDone={setHeader1Done}
                    />
                  </h1>
                )}
                {!content.paragraphs[0]?.text ? (
                  <ParagraphSkeleton />
                ) : (
                  header1Done && (
                    <TypewriterText
                      id="xthings-hero-paragraph-0"
                      className="text-base font-light desktop:col-span-6"
                      text={content.paragraphs[0].text}
                      isDone={para1Done}
                      setIsDone={setPara1Done}
                    />
                  )
                )}
              </div>
              <div className="mobile:mb-[22px] mobile:p-[44px] tablet:px-[116px] tablet:py-[64px] desktop:p-[44px] rounded-lg bg-grey-300/95 mobile:min-h-[200px] desktop:min-h-[322px] desktop:w-[450px] flex flex-col grow items-start ">
                {!content.paragraphs[1] ? (
                  <TitleSkeleton />
                ) : (
                  para1Done && (
                    <h1 className="text-2xl font-normal desktop:min-h-20">
                      <TypewriterText
                        text={content.paragraphs[1].header}
                        type="strong"
                        isDone={header2Done}
                        setIsDone={setHeader2Done}
                      />
                    </h1>
                  )
                )}
                {!content.paragraphs[1]?.text ? (
                  <ParagraphSkeleton />
                ) : (
                  header2Done && (
                    <TypewriterText
                      id="xthings-hero-paragraph-1"
                      className="text-base font-light desktop:col-span-6"
                      text={content.paragraphs[1].text}
                      isDone={para2Done}
                      setIsDone={setPara2Done}
                    />
                  )
                )}
              </div>
              <div className="mobile:mb-[22px] rounded-lg mobile:p-[44px] tablet:px-[116px] tablet:py-[64px] desktop:p-[44px] bg-grey-400/95 mobile:min-h-[200px] desktop:min-h-[322px] desktop:w-[450px] flex flex-col grow items-start ">
                {!content.paragraphs[2] ? (
                  <TitleSkeleton />
                ) : (
                  para2Done && (
                    <h1 className="text-2xl font-normal desktop:min-h-20">
                      <TypewriterText
                        text={content.paragraphs[2].header}
                        type="strong"
                        isDone={header3Done}
                        setIsDone={setHeader3Done}
                      />
                    </h1>
                  )
                )}
                {!content.paragraphs[2]?.text ? (
                  <ParagraphSkeleton />
                ) : (
                  header3Done && (
                    <TypewriterText
                      id="xthings-hero-paragraph-2"
                      className="text-base font-light desktop:col-span-6"
                      text={content.paragraphs[2].text}
                      isDone={para3Done}
                      setIsDone={setPara3Done}
                    />
                  )
                )}
              </div>
            </div>

            <div
                id="button-scroll-container"
                className="desktop:mb-[134px] mb-[72px] mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-12 desktop:row-start-4 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
              >
                <div className={classNames(
                    "mobile:hidden desktop:block pt-16",
                    {
                      ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 desktop:visible"]: para3Done,  
                      ["desktop:translate-y-full desktop:invisible desktop:h-0"]: !para3Done,                          
                    }
                  )}
                  >
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="image"
                  />
                </div>
              </div>   
          </Grid>
          <div
            id="question-container"              
            className={classNames(
              "mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: para3Done,  
                ["desktop:translate-y-full mobile:invisible mobile:h-4 desktop:h-0"]: !para3Done,      
                ["mobile:invisible mobile:h-4 desktop:h-0"]: hideQuestion === "hide",      
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para3Done}
              onSubmit={askQuestion}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}  
              customMargin="!mx-0"  
            />
          </div>
          </div>
        </div>
      </div>
    );
  }
);

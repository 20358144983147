import { useState } from "react";

export default function VideoTest() {
  const [url, setUrl] = useState<string>("");

  return (
    <div className="h-full w-full block font-inter relative">
      <div className="p-6">
        <label>URL: </label>
        <input onChange={(e) => setUrl(e.target.value)} value={url} className="bg-grey-300 w-1/2" />
      </div>
      <div className="relative" style={{ paddingTop: "56.25%" }}>
        <iframe
          className="absolute inset-0 w-full h-full"
          src={url}
        ></iframe>
      </div>
    </div>
  );
}

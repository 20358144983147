import { MutableRefObject, Ref, forwardRef, useEffect, useState } from "react";
import { Grid, QuestionBoxFullWidth } from "../";
import { useChat } from "../../context/chat-context";
import classNames from "classnames";
export const Fail = forwardRef(
  (
    {
      failText,
      templateOrderIndex,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate
    }: {
      failText: string;
      templateOrderIndex: number;
      loading: boolean;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
      askQuestion: (query: string) => void;
    },
    ref: Ref<HTMLDivElement>
  ) => {  
  const { questions } = useChat();
  const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
  const [hideQuestion, setHideQuestions] = useState("show");
  useEffect(() => {
    setNextTemplate(ref)
  }, [])

  const lastFollowUps =
    [...questions].reverse().find((q) => q.response?.followUps.length)?.response
      ?.followUps || [];

  const userQuestion = [...questions]
    .reverse()
    .find((q) => q.response?.followUps.length)?.response?.userQuestion;

  return (
    <div ref={ref} id="fail" className="relative">
      <Grid
        customClasses={`bg-white mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] gap-x-4 h-full relative py-[30px] rounded-t-[3.125rem] -mt-[2.5rem]`}
      >
        <div className="mobile:col-start-1 desktop:col-start-2 col-span-full desktop:pt-[84px] pt-[42px] desktop:pb-[30px] pb-[36px]">
          <h3
            id="long-hero-tagline"
            className="mobile:text-base desktop:text-xl font-extrabold uppercase"
          >
            This query has failed
          </h3>
        </div>
        <div
          id="long-hero-subheading-content"
          className="mobile:col-start-1 desktop:col-start-2 col-span-full desktop:pb-[134px] pb-[72px]"
        >
          <p id="fail-message" className="text-2xl font-light">
            {failText}
          </p>
        </div>
        <div
          id="question-container"              
          className={classNames(
            "col-span-full mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
            {
              ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: failText,  
              ["desktop:translate-y-full mobile:invisible mobile:h-0"]: !failText,      
              ["mobile:invisible mobile:h-4 desktop:h-0"]: hideQuestion === "hide",      
            }
          )}
        >
          <QuestionBoxFullWidth
            templateOrderIndex={templateOrderIndex}
            style="lightTxt"
            backgroundColour="background-brand"
            followupQuestions={lastFollowUps}
            isLoading={!failText}
            onSubmit={askQuestion}
            optionQuestion={userQuestion}
            nextTemplate={nextTemplateRef}
            sethideQuestion={setHideQuestions}   
            customMargin="!mx-0" 
          />
        </div>
      </Grid>
    </div>
  );
});

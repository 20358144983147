import { MutableRefObject, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid, QuestionBoxFullWidth } from "../../../components";
import { TitleSkeleton, ParagraphSkeleton } from "../../../components/Skeleton";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import { QuestionResponse } from "../../../interface";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import MediaLinks from "../../../components/MediaLinks/MediaLinks";

export const LongHero = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {    
    const { content, followUps, userQuestion, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState("show");
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
        
    useEffect(() => {
      setNextTemplate(ref)
    }, [])

    return (
      <div ref={ref} id="long-hero" className={classNames(
        "relative rounded-t-[3.125rem]",
        {
          ["-mt-[2.5rem]"]: templateOrderIndex !== 0,   
          ["desktop:mt-[2.5%]"]: templateOrderIndex === 0,                    
        }
      )}>
        <div
          style={{ backgroundImage: `url('${content?.imgURL}')` }}
          className="h-full desktop:mx-[2.5%] bg-no-repeat bg-center bg-cover rounded-t-[3.125rem]"
        >
          <div className="bg-black/50 text-white h-full w-full rounded-t-[3.125rem] backdrop-blur">
            <Grid customClasses="gap-x-4 relative h-full desktop:!mx-0 mobile:pb-[4.5rem] desktop:pb-[8.375rem] rounded-t-[3.125rem]">
              <div
                id="long-hero-tagline-content"
                className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-22 mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] desktop:row-start-1 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
              >
                <div
                  id="long-hero-tagline"
                  className="mobile:text-base desktop:text-xl font-medium uppercase"
                >
                  {query}
                </div>
              </div>
              <div
                id="long-hero-header-content"
                className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-12 mobile:pt-[1.375rem] tablet:pt-[1.375rem] desktop:pt-[1.875rem] desktop:row-start-2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
              >
                {!content.header ? (
                  <TitleSkeleton />
                ) : (
                  <h1
                    id="long-hero-header"
                    className="mobile:text-4xl desktop:text-5xl font-normal"
                  >
                    {content.header}
                  </h1>
                )}
              </div>
              <div
                id="long-hero-subheading-content"
                className="mobile:col-start-1 desktop:col-start-13 mobile:col-span-full desktop:col-end-22 mobile:pt-[1.375rem] tablet:pt-[1.375rem] desktop:pt-[2.75rem] desktop:row-start-2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
              >
                {!content.tagline ? (
                  <ParagraphSkeleton />
                ) : (
                  <h3 id="long-hero-subheading" className="text-2xl font-light">
                    {content.tagline}
                  </h3>
                )}
              </div>

              <div
                id="long-hero-paragraph-content-1"
                className="desktop:min-h-[200px] mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-12 mobile:pt-[1.375rem] tablet:pt-[1.375rem] desktop:pt-[1.875rem] desktop:row-start-3 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
              >
                {!content.paragraphs[0] ? (
                  <ParagraphSkeleton />
                ) : (
                  <TypewriterText
                    id="long-hero-paragraph-1"
                    className="text-base font-light"
                    text={content.paragraphs[0].text}
                    isDone={para1Done}
                    setIsDone={setPara1Done}
                  />
                )}
              </div>

              <div
                id="long-hero-paragraph-content-2"
                className="desktop:min-h-[200px] mobile:col-start-1 desktop:col-start-13 mobile:col-span-full desktop:col-end-22 mobile:pt-[1.375rem] tablet:pt-[1.375rem] desktop:pt-[1.875rem] desktop:row-start-3 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
              >
                {!content.paragraphs[1] ? (
                  <ParagraphSkeleton />
                ) : (
                  para1Done && (
                    <TypewriterText
                      id="long-hero-paragraph-2"
                      className="text-base font-light"
                      text={content.paragraphs[1].text}
                      isDone={para2Done}
                      setIsDone={setPara2Done}
                    />
                  )
                )}
                {para2Done && <MediaLinks assets={assets} />}
              </div>

              <div
                id="button-scroll-container"
                className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-12 desktop:row-start-4 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
              >
                <div className={classNames(
                    "mobile:hidden desktop:block pt-16",
                    {
                      ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 desktop:visible"]: para2Done,  
                      ["desktop:translate-y-full desktop:invisible desktop:h-0"]: !para2Done,                          
                    }
                  )}
                  >
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="image"
                  />
                </div>
              </div>              
            </Grid>
            <div
              id="question-container"              
              className={classNames(
                "mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
                {
                  ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: para2Done,  
                  ["desktop:translate-y-full mobile:invisible mobile:h-4 desktop:h-0"]: !para2Done,      
                  ["mobile:h-4 desktop:h-0 mobile:invisible"]: hideQuestion === "hide",      
                }
              )}
            >
              <QuestionBoxFullWidth
                templateOrderIndex={templateOrderIndex}
                style="lightTxt"
                backgroundColour="background-brand"
                followupQuestions={followUps}
                isLoading={!para2Done}
                onSubmit={askQuestion}
                optionQuestion={userQuestion}   
                sethideQuestion={setHideQuestions}         
                nextTemplate={nextTemplateRef}
                customMargin="!mx-0"
            />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

import { MutableRefObject, Ref, forwardRef, useEffect, useState } from "react";
import { Grid } from "../../../components";
import { QuestionResponse } from "../../../interface";
import { TitleSkeleton, ParagraphSkeleton } from "../../../components/Skeleton";
import classnames from "classnames";
import { QuestionBoxFullWidth } from "../../../components/QuestionBox/QuestionBoxFullWidth";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";

export const LongInform = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState("show");
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;

    useEffect(() => {
      console.log("longInform");
      setNextTemplate(ref);
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    return (
      <div ref={ref} id="long-inform" className="relative">
        {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
        <Grid
          customClasses={classnames(
            "gap-x-4 min-h-full relative rounded-t-[3.125rem] -mt-[2.5rem]",
            {
              ["bg-white text-black"]: version === "white",
              ["bg-grey-200 text-black"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}
        >
          <div
            id="long-inform-header-content"
            className="mobile:col-start-1 desktop:col-start-2 col-span-full mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0"
          >
            <div
              id="long-inform-tagline"
              className="mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:text-base desktop:text-xl font-medium uppercase"
            >
              {query}
            </div>
            {!content.header ? (
              <TitleSkeleton rows={1} />
            ) : (
              <h1
                id="long-inform-header"
                className="mobile:pt-[1.5rem] desktop:pt-[1.875rem] mobile:text-4xl desktop:text-5xl font-normal"
              >
                {content.header}
              </h1>
            )}
          </div>

          <div
            id="long-inform-image-content"
            className="mobile:col-start-1 desktop:col-start-2 desktop:col-span-6 mobile:col-span-full mobile:pt-[2.25rem] desktop:pt-[1.375rem] desktop:flex desktop:flex-col desktop:justify-between mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0 mobile:pb-[4.5rem] desktop:pb-[8.375rem]"
          >
            {!content.tagline ? (
              <ParagraphSkeleton lines={6} />
            ) : (
              <p id="long-inform-paragraph-1" className="text-2xl font-light">
                {content.tagline}
              </p>
            )}
            <div className={classNames("desktop:pt-16 mobile:hidden desktop:block",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 desktop:visible"]: para2Done,  
                ["desktop:translate-y-full desktop:invisible desktop:h-0"]: !para2Done,                          
              }
            )}
            >
              {version === "white" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="white"
                />
              )}
              {version === "dark" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="dark"
                />
              )}
              {version === "grey" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="grey"
                />
              )}
            </div>
          </div>
          <div
            id="long-inform-answer-content"
            className="mobile:col-start-1 desktop:col-start-9 mobile:col-span-full desktop:col-end-24 mobile:pt-[1.375rem] desktop:pt-[1.375rem] mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0 mobile:pb-[4.5rem] desktop:pb-[8.375rem]"
          >
            {!content.paragraphs[0] ? (
              <ParagraphSkeleton lines="default" classNames="mb-[1.375rem]" />
            ) : (
              <TypewriterText
                id="long-inform-paragraph-2"
                className="text-base font-light pb-[1.375rem]"
                text={content.paragraphs[0].text}
                isDone={para1Done}
                setIsDone={setPara1Done}
              />
            )}

            {!content.paragraphs[1] ? (
              <ParagraphSkeleton lines="tablet" />
            ) : (
              para1Done && (
                <TypewriterText
                  id="long-inform-paragraph-3"
                  className="text-base font-light"
                  text={content.paragraphs[1].text}
                  isDone={para2Done}
                  setIsDone={setPara2Done}
                />
              )
            )}            
          </div>          
          <div
            id="question-container"              
            className={classNames(
              "col-span-full mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: para2Done,  
                ["desktop:translate-y-full mobile:invisible mobile:h-4 desktop:h-0"]: !para2Done,      
                ["mobile:invisible mobile:h-4 desktop:h-0"]: hideQuestion === "hide",      
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para2Done}
              onSubmit={askQuestion}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}  
              customMargin="!mx-0"              
            />
          </div>
        </Grid>
      </div>
    );
  }
);

import { MutableRefObject, Ref, forwardRef, useEffect, useState } from "react";
import { Grid, QuestionBoxFullWidth } from "../../../components";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
  OneLineQuestionSkeleton,
} from "../../../components/Skeleton";
import classnames from "classnames";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import { QuestionResponse } from "../../../interface";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";

export const XThingsInspire = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query } = response;

    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [para3Done, setPara3Done] = useState(false);
    const [header1Done, setHeader1Done] = useState(false);
    const [header2Done, setHeader2Done] = useState(false);
    const [header3Done, setHeader3Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState("show");
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
        
    useEffect(() => {
      setNextTemplate(ref)
    }, [])

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    return (
      <div ref={ref} id="xthings-inspire" className="relative">
        <Grid
          customClasses={classnames(
            "gap-x-4 h-full desktop:mx-0 relative x-inspire rounded-t-[3.125rem] -mt-[2.5rem]",
            {
              ["bg-white"]: version === "white",
              ["bg-grey-200"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}
        >
          <div className="mobile:col-start-1 desktop:col-start-1 mobile:col-span-full desktop:col-end-13 desktop:row-start-1 h-full desktop:min-h-screen">
            {!content.imgURL ? (
              <>
                <div className="mobile:hidden tablet:hidden desktop:block">
                  <ImageSkeleton display="desktop" />
                </div>
                <div className="mobile:block desktop:hidden">
                  <ImageSkeleton display="mobile" />
                </div>
              </>
            ) : (
              <>
                {!content.imgURL ? (
                  <div className="mobile:rounded-t-[3.125rem] desktop:rounded-tl-[3.125rem] w-full mobile:h-[500px] tablet:h-[450px] desktop:h-screen max-h-full bg-gradient-to-r from-cyan-500 to-blue-500"></div>
                ) : (
                  <div className="relative w-full h-full">
                    <div
                      id="xthings-inspire-image-overlay"
                      className="mobile:rounded-t-[3.125rem] desktop:rounded-tl-[3.125rem] absolute top-0 left-0 w-full h-full min-h-full max-h-full bg-black/50"
                    ></div>
                    <div
                      id="xthings-inspire-main-image"
                      style={{ backgroundImage: `url('${content.imgURL}')` }}
                      className={`mobile:rounded-t-[3.125rem] desktop:rounded-tl-[3.125rem] w-full mobile:h-[500px] tablet:h-[450px] desktop:h-full min-h-full max-h-full bg-cover bg-center bg-no-repeat`}
                    ></div>
                  </div>
                )}
              </>
            )}
          </div>
          <div
            id="xthings-inspire-header-content"
            className="desktop:flex desktop:flex-col desktop:justify-between desktop:row-start-1 mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-span-10 z-5 absolute desktop:top-[5.25rem] mobile:top-[2.625rem] left-0 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <div>
              <div
                id="tagline"
                className="mobile:text-base desktop:text-xl font-medium text-white uppercase"
              >
                {query}
              </div>
              <div className="mobile:pt-[1.5rem] tablet:pt-[1.5rem] desktop:pt-[2.25rem]">
                {!content.header ? (
                  <TitleSkeleton />
                ) : (
                  <h1
                    id="xthings-inspire-header"
                    className="mobile:text-4xl tablet:text-5xl font-normal text-white desktop:pb-[3.125rem]"
                  >
                    {content.header}
                  </h1>
                )}
              </div>
            </div>

            <div className={classNames(
              "mobile:hidden desktop:block pt-16",
                {
                  ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 desktop:visible"]: para3Done,  
                  ["desktop:translate-y-full desktop:invisible desktop:h-0"]: !para3Done,                          
                }
              )}
            >              
              <ButtonScrollDown
                customClasses="relative"
                versionColour="dark"
              />              
            </div>           
          </div>
          <div
            id="xthings-inspire-paragraph-content"
            className="desktop:row-start-1 mobile:col-start-1 desktop:col-start-13 mobile:col-span-full desktop:col-end-24 flex flex-col mobile:px-8 tablet:px-[3.125rem] desktop:pt-[5.25rem] desktop:pr-8 mobile:pb-[4.5rem] desktop:pb-[8.375rem]"
          >
            {" "}
            {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
            <div className="mobile:pt-[1.5rem] tablet:pt-[1.375rem] desktop:pt-0">
              {!content.paragraphs[0] ? (
                <OneLineQuestionSkeleton />
              ) : (
                <p className="text-2xl mb-2">
                  <TypewriterText
                    text={content.paragraphs[0].header}
                    type="strong"
                    isDone={header1Done}
                    setIsDone={setHeader1Done}
                  />
                </p>
              )}
              {!content.paragraphs[0]?.text ? (
                <ParagraphSkeleton />
              ) : (
                header1Done && (
                  <TypewriterText
                    id="xthings-inspire-paragraph-1"
                    className="text-base font-light col-span-6"
                    text={content.paragraphs[0].text}
                    isDone={para1Done}
                    setIsDone={setPara1Done}
                  />
                )
              )}
            </div>
            <div className="mobile:pt-[1.5rem] tablet:pt-[1.375rem] desktop:pt-[2.25rem]">
              {!content.paragraphs[1] ? (
                <OneLineQuestionSkeleton />
              ) : (
                para1Done && (
                  <p className="text-2xl mb-2">
                    <TypewriterText
                      text={content.paragraphs[1].header}
                      type="strong"
                      isDone={header2Done}
                      setIsDone={setHeader2Done}
                    />
                  </p>
                )
              )}
              {!content.paragraphs[1]?.text ? (
                <ParagraphSkeleton />
              ) : (
                header2Done && (
                  <TypewriterText
                    id="xthings-inspire-paragraph-2"
                    className="text-base font-light col-span-6"
                    text={content.paragraphs[1].text}
                    isDone={para2Done}
                    setIsDone={setPara2Done}
                  />
                )
              )}
            </div>
            <div className="mobile:pt-[1.5rem] tablet:pt-[1.375rem] desktop:pt-[2.25rem]">
              {!content.paragraphs[2] ? (
                <OneLineQuestionSkeleton />
              ) : (
                para2Done && (
                  <p className="text-2xl mb-2">
                    <TypewriterText
                      text={content.paragraphs[2].header}
                      type="strong"
                      isDone={header3Done}
                      setIsDone={setHeader3Done}
                    />
                  </p>
                )
              )}
              {!content.paragraphs[2]?.text ? (
                <ParagraphSkeleton />
              ) : (
                header3Done && (
                  <TypewriterText
                    id="xthings-inspire-paragraph-2"
                    className="text-base font-light col-span-6"
                    text={content.paragraphs[2].text}
                    isDone={para3Done}
                    setIsDone={setPara3Done}
                  />
                )
              )}
            </div>             
          </div>
          <div
            id="question-container"              
            className={classNames(
              "col-span-full desktop:!mx-0 mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: para3Done,  
                ["desktop:translate-y-full mobile:invisible mobile:h-4 desktop:h-0"]: !para3Done,      
                ["mobile:invisible mobile:h-4 desktop:h-0"]: hideQuestion === "hide",      
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para3Done}
              onSubmit={askQuestion}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}    
              customMargin="!mx-0"
            />
          </div>
        </Grid>
      </div>
    );
  }
);

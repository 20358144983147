import { MutableRefObject, Ref, forwardRef, useEffect, useState } from "react";
import { Grid, QuestionBoxFullWidth } from "../../../components";
import { QuestionResponse } from "../../../interface";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
} from "../../../components/Skeleton";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import MediaLinks from "../../../components/MediaLinks/MediaLinks";

export const ShortHero = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState("show");
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
        
    useEffect(() => {
      setNextTemplate(ref)
    }, [])

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    return (
      <div ref={ref} id="short-hero" className={classNames(
        "relative rounded-t-[3.125rem]",
        {
          ["-mt-[2.5rem]"]: templateOrderIndex !== 0,
          ["desktop:mt-[2.5%]"]: templateOrderIndex === 0,                      
        }
      )}>
        {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
        <Grid
          customClasses={classNames(
            "gap-x-4 h-full relative desktop:pt-[5.25rem]",
            {
              ["bg-white"]: version === "white",
              ["bg-grey-200"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}
        >
          <div
            id="short-hero-container"
            className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-24"
          >
            {!content.imgURL ? (
              <div>
                <div className="mobile:hidden tablet:block desktop:block">
                  <ImageSkeleton display="hero-short" />
                </div>
                <div className="tablet:hidden desktop:hidden">
                  <ImageSkeleton display="hero-short-mobile" />
                </div>
              </div>
            ) : (
              <>
                {!content.imgURL ? (
                  <div className="w-full mobile:h-[250px] tablet:h-[400px] max-h-[400px] bg-gradient-to-b from-[#8B919A] to-white"></div>
                ) : (
                  <div
                    id="short-hero-image"
                    style={{ backgroundImage: `url('${content.imgURL}')` }}
                    className={`z-3 w-full mobile:h-[250px] tablet:h-[400px] max-h-[400px] bg-cover bg-center bg-no-repeat`}
                  ></div>
                )}
              </>
            )}
          </div>

          <div
            id="short-hero-answer-content"
            className="mobile:col-start-1 mobile:col-span-full desktop:col-start-3 desktop:col-end-23 desktop:col-span-16 w-full h-full mobile:pt-[1.375rem] desktop:pt-[1.75rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 mobile:pb-[4.5rem] desktop:pb-[8.375rem]"
          >
            <div
              id="short-hero-tagline"
              className="mobile:text-base desktop:text-xl font-medium uppercase"
            >
              {query}
            </div>
            <div className="mobile:pt-[1.5rem] desktop:pt-[1.875rem]">
              {!content.header ? (
                <TitleSkeleton />
              ) : (
                <h1
                  id="short-hero-header"
                  className="mobile:text-4xl desktop:text-5xl font-medium"
                >
                  {content.header}
                </h1>
              )}
            </div>
            <div id="short-hero-paragraph-content-1" className="pt-[1.375rem]">
              {!content.paragraphs[0] ? (
                <ParagraphSkeleton />
              ) : (
                <TypewriterText
                  id="short-hero-paragraph-1"
                  className="text-[1.063rem] font-light"
                  text={content.paragraphs[0].text}
                  isDone={para1Done}
                  setIsDone={setPara1Done}
                />
              )}
              {para1Done && <MediaLinks assets={assets} />}     
            </div>
            <div className={classNames(
              "mobile:hidden desktop:block pt-16",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 desktop:visible"]: para1Done,  
                ["desktop:translate-y-full desktop:invisible desktop:h-0"]: !para1Done,                          
              }
            )}
            >
              {version === "white" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="white"
                />
              )}
              {version === "dark" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="dark"
                />
              )}
              {version === "grey" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="grey"
                />
              )}
            </div>      
          </div>
        <div
          id="question-container"              
          className={classNames(
            "col-span-full mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
            {
              ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: para1Done,  
              ["desktop:translate-y-full mobile:h-4 desktop:h-0 mobile:invisible"]: !para1Done,      
              ["mobile:invisible mobile:h-4 desktop:h-0"]: hideQuestion === "hide",      
            }
          )}
        >
          <QuestionBoxFullWidth
            templateOrderIndex={templateOrderIndex}
            style="lightTxt"
            backgroundColour="background-brand"
            followupQuestions={followUps}
            isLoading={!para1Done}
            onSubmit={askQuestion}
            optionQuestion={userQuestion}
            nextTemplate={nextTemplateRef}
            sethideQuestion={setHideQuestions}          
            customMargin="!mx-0"    
          />
        </div>
        </Grid>
      </div>
    );
  }
);

import { MutableRefObject, Ref, forwardRef, useEffect, useState } from "react";
import { Grid, QuestionBoxFullWidth } from "../../../components";
import { QuestionResponse } from "../../../interface";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
} from "../../../components/Skeleton";
import classnames from "classnames";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";

export const LongInspire = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      setNextTemplate,
      nextTemplate
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState("show");
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
        
    useEffect(() => {
      setNextTemplate(ref)
    }, [])

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    return (
      <div ref={ref} id="long-inspire" className="relative">
        {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
        <Grid
          customClasses={classnames(
            "gap-x-4 h-full relative rounded-t-[3.125rem] -mt-[2.5rem]",
            {
              ["bg-white"]: version === "white",
              ["bg-grey-200"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}
        >
          <div
            id="long-inspire-image-contaier"
            className="col-start-1 col-span-full"
          >
            {!content.imgURL ? (
              <div>
                <div className="mobile:hidden desktop:block">
                  <ImageSkeleton display="desktop-top" />
                </div>
                <div className="desktop:hidden">
                  <ImageSkeleton display="mobile" />
                </div>
              </div>
            ) : (
              <>
                {!content.imgURL ? (
                  <div className="rounded-t-[3.125rem] w-full mobile:h-[450px] tablet:h-[450px] desktop:h-[450px] max-h-[450px] bg-gradient-to-b from-[#8B919A] to-white"></div>
                ) : (
                  <div className="relative">
                    <div
                      id="long-inspire-image-overlay"
                      className="rounded-t-[3.125rem] absolute top-0 left-0 mobile:w-full desktop:w-4/5 mobile:h-[450px] tablet:h-[450px] desktop:h-[450px] mobile:bg-gradient-to-b desktop:bg-gradient-to-r from-background-brand from-55%"
                    ></div>
                    <div
                      id="long-inspire-image"
                      style={{ backgroundImage: `url('${content.imgURL}')` }}
                      className={`rounded-t-[3.125rem] z-3 w-full mobile:h-[450px] tablet:h-[450px] desktop:h-[450px] min-h-[450px] bg-cover bg-center bg-no-repeat`}
                    ></div>
                  </div>
                )}
              </>
            )}
          </div>
          <div
            id="long-inspire-header-content"
            className="mobile:col-start-1 desktop:col-start-2 col-span-full z-5 absolute mobile:top-[4.25rem] tablet:top-[2.625rem] desktop:top-[5.25rem] left-0 mobile:w-full desktop:w-1/2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <div
              id="long-inspire-tagline"
              className="mobile:text-base desktop:text-xl font-medium text-white uppercase"
            >
              {query}
            </div>
            {!content.header ? (
              <TitleSkeleton />
            ) : (
              <h1
                id="long-inspire-header"
                className="mobile:pt-[2.25rem] tablet:pt-[1.5rem] desktop:pt-[2.25rem] mobile:text-4xl tablet:text-5xl font-normal text-white"
              >
                {content.header}
              </h1>
            )}
          </div>

          <div
            id="long-inspire-left-answer-content"
            className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-span-7 mobile:pt-[2.25rem] tablet:pt-[1.375rem] desktop:pt-[2.25rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:flex desktop:flex-col desktop:justify-between desktop:pb-[8.375rem]"
          >
            {!content.tagline ? (
              <ParagraphSkeleton />
            ) : (
              <h3 id="long-inspire-paragraph-1" className="text-2xl font-light">
                {content.tagline}
              </h3>
            )}
            <div className={classNames(
              "mobile:hidden desktop:block pt-16",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 desktop:visible"]: para2Done,  
                ["desktop:translate-y-full desktop:invisible desktop:h-0"]: !para2Done,                          
              }
            )}
            >
              {version === "white" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="white"
                />
              )}
              {version === "dark" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="dark"
                />
              )}
              {version === "grey" && (
                <ButtonScrollDown
                  customClasses="relative"
                  versionColour="grey"
                />
              )}
            </div>
          </div>

          <div
            id="long-inspire-right-answer-content"
            className="mobile:col-start-1 desktop:col-start-10 mobile:col-span-full desktop:col-end-24 w-full h-full mobile:pt-[2.25rem] tablet:pt-[1.375rem] desktop:pt-[2.25rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 mobile:pb-[4.5rem] desktop:pb-[8.375rem]"
          >
            <div className="">
              {!content.paragraphs[0] ? (
                <ParagraphSkeleton />
              ) : (
                <TypewriterText
                  id="long-inspire-paragraph-2"
                  className="text-base font-light"
                  text={content.paragraphs[0].text}
                  isDone={para1Done}
                  setIsDone={setPara1Done}
                />
              )}
            </div>
            {!content.paragraphs[1] ? (
              <ParagraphSkeleton />
            ) : (
              para1Done && (
                <div className="pt-[1.375rem]">
                  <TypewriterText
                    id="long-inspire-paragraph-2"
                    className="text-base font-light"
                    text={content.paragraphs[1].text}
                    isDone={para2Done}
                    setIsDone={setPara2Done}
                  />
                </div>
              )
            )}            
          </div>
          <div
            id="question-container"              
            className={classNames(
              "col-span-full mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
              {
                ["desktop:translate-y-0 desktop:transition-all desktop:duration-200 mobile:visible"]: para2Done,  
                ["desktop:translate-y-full mobile:invisible mobile:h-4 desktop:h-0"]: !para2Done,      
                ["mobile:invisble mobile:h-4 desktop:h-0"]: hideQuestion === "hide",      
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para2Done}
              onSubmit={askQuestion}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}       
              customMargin="!mx-0"     
            />
          </div>
        </Grid>
      </div>
    );
  }
);

import { Ref, forwardRef } from "react";
import { ContextMetadataItem } from "../../interface";

export const ContentRail = forwardRef(
  (
    {
      metadata,
    }: {
      metadata: ContextMetadataItem[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className=" col-start-1 desktop:col-start-2 desktop:mx-[2.5%] desktop:px-[75px] tablet:px-[50px] mobile:px-[20px] col-span-full bg-grey-1000"
      >
        <h3 className="font-size-2xl pt-[64px] text-white">
          BASED ON YOUR LAST QUESTION, WE RECOMMEND THE FOLLOWING PAGES
        </h3>

        <div className="overflow-y-auto flex flex-row space-x-[100px]">
          <a href={metadata[0]?.page_url} target="_blank">
            <div className="w-[263px] py-3 px-2">
              <div
                style={{ backgroundImage: `url(${metadata[0]?.thumbnail})` }}
                className="w-full h-[156px] w-[263px] bg-no-repeat bg-cover bg-center my-8"
                title={metadata[0]?.page_title}
              />
              <h3 className="text-xl text-white mb-2">
                {metadata[0]?.page_title}
              </h3>
            </div>
          </a>
          <a href={metadata[1]?.page_url} target="_blank">
            <div className="w-[263px] py-3 px-2">
              <div
                style={{ backgroundImage: `url(${metadata[1]?.thumbnail})` }}
                className="w-full h-[156px] w-[263px] bg-no-repeat bg-cover bg-center my-8"
                title={metadata[1]?.page_title}
              />

              <h3 className="text-xl text-white mb-2">
                {metadata[1]?.page_title}
              </h3>
            </div>
          </a>
          <a href={metadata[2]?.page_url} target="_blank">
            <div className="w-[263px] py-3 px-2">
              <div
                style={{ backgroundImage: `url(${metadata[2]?.thumbnail})` }}
                className="w-full h-[156px] w-[263px] bg-no-repeat bg-cover bg-center my-8"
                title={metadata[2]?.page_title}
              />

              <h3 className="text-xl text-white mb-2">
                {metadata[2]?.page_title}
              </h3>
            </div>
          </a>
          <a href={metadata[3]?.page_url} target="_blank">
            <div className="w-[263px] py-3 px-2">
              <div
                style={{ backgroundImage: `url(${metadata[3]?.thumbnail})` }}
                className="w-full h-[156px] w-[263px] bg-no-repeat bg-cover bg-center my-8"
                title={metadata[3]?.page_title}
              />

              <h3 className="text-xl text-white mb-2">
                {metadata[3]?.page_title}
              </h3>
            </div>
          </a>
        </div>
      </div>
    );
  }
);

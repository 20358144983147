import React, { useEffect, useRef } from "react";
import "./MovingSpinningCirclesCSS.css";

export const MovingSpinningCircles = () => {
  const canvasRef = useRef(null);
  const overlayCanvasRef = useRef(null);
  const svgRef = useRef(null);
  let isDisturbed = false;

  useEffect(() => {
    const canvas = canvasRef.current;
    const overlayCanvas = overlayCanvasRef.current;
    const ctx = canvas.getContext("2d");
    const overlayCtx = overlayCanvas.getContext("2d");
    const svgElement = svgRef.current;

    const square = {
      x: 0,
      y: 0,
      size: 100,
    };

    const circles = [
      {
        x: 30,
        y: 30,
        angle: 0,
        rotation: 0,
        radius: 30,
        baseRadius: 30,
        speed: 0.01,
        offsetX: 0,
        offsetY: 0,
      },
      {
        x: 70,
        y: 70,
        angle: 120,
        rotation: 0,
        radius: 30,
        baseRadius: 30,
        speed: 0.001,
        offsetX: 0,
        offsetY: 0,
      },
      {
        x: 50,
        y: 50,
        angle: 240,
        rotation: 0,
        radius: 30,
        baseRadius: 30,
        speed: 0.02,
        offsetX: 0,
        offsetY: 0,
      },
    ];

    const createGradient = (ctx, circle) => {
      const gradient = ctx.createLinearGradient(
        circle.x + circle.radius * Math.cos(circle.rotation),
        circle.y + circle.radius * Math.sin(circle.rotation),
        circle.x - circle.radius * Math.cos(circle.rotation),
        circle.y - circle.radius * Math.sin(circle.rotation)
      );
      gradient.addColorStop(0, "rgba(255, 255, 255, 1)");
      gradient.addColorStop(0.3, "rgba(250, 219, 172, 0.5)");
      gradient.addColorStop(0.7, "rgba(0, 110, 232, 0.5)");
      gradient.addColorStop(1, "rgba(0, 110, 232, 0)");
      return gradient;
    };

    const drawCircle = (ctx, circle) => {
      ctx.fillStyle = createGradient(ctx, circle);
      ctx.beginPath();
      ctx.arc(
        circle.x + circle.offsetX,
        circle.y + circle.offsetY,
        circle.radius,
        0,
        Math.PI * 2,
        false
      );
      ctx.fill();
      ctx.closePath();
    };

    const updateCircle = (circle) => {
      circle.angle += circle.speed;
      circle.rotation += circle.speed * 2;

      const radius = square.size / 27;
      circle.x = square.x + square.size / 2 + radius * Math.cos(circle.angle);
      circle.y = square.y + square.size / 2 + radius * Math.sin(circle.angle);

      circle.radius = circle.baseRadius + Math.sin(circle.angle * 2) * 6;

      if (!isDisturbed) {
        circle.offsetX *= 0.9;
        circle.offsetY *= 0.9;
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      overlayCtx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

      circles.forEach((circle) => {
        updateCircle(circle);
        drawCircle(ctx, circle);
      });

      overlayCtx.drawImage(canvas, 0, 0);
      overlayCtx.drawImage(overlayCanvas, 0, 0);

      const scale = 1 + 0.05 * Math.sin(circles[0].angle * 2.1);
      svgElement.style.transform = `translate(-50%, -50%) scale(${scale})`;

      requestAnimationFrame(animate);
    };

    animate();

    const handleKeyDown = (e) => {
      isDisturbed = true;
      circles.forEach((circle) => {
        circle.offsetX += (Math.random() - 0.5) * 5;
        circle.offsetY += (Math.random() - 0.5) * 5;
      });
      setTimeout(() => {
        isDisturbed = false;
      }, 200);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="avatar">
      <canvas id="canvas" ref={canvasRef} width="100" height="100"></canvas>
      <canvas
        id="overlayCanvas"
        ref={overlayCanvasRef}
        width="100"
        height="100"
      ></canvas>
      <svg
        ref={svgRef}
        className="svgElem pb-[15px] pr-[15px]"
        width="27"
        height="34"
        viewBox="0 0 27 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="svgElement"
      >
        <path
          d="M14.2139 1.64445C14.076 1.30534 13.5842 1.30534 13.4463 1.64444L10.9242 7.84599C10.4304 9.06022 9.44506 10.0218 8.20088 10.5037L1.84635 12.9651C1.49888 13.0997 1.49888 13.5796 1.84635 13.7142L8.20087 16.1756C9.44506 16.6575 10.4304 17.6191 10.9242 18.8333L13.4463 25.0349C13.5842 25.374 14.076 25.374 14.2139 25.0349L16.736 18.8334C17.2298 17.6191 18.2151 16.6575 19.4593 16.1756L25.8138 13.7143C26.1613 13.5797 26.1613 13.0997 25.8138 12.9651L19.4593 10.5037C18.2151 10.0218 17.2298 9.06023 16.736 7.84599L14.2139 1.64445Z"
          fill="white"
        />
        <path
          d="M7.15335 24.6132C7.01543 24.2741 6.52363 24.2741 6.38572 24.6132L6.03951 25.4645C5.54569 26.6787 4.56039 27.6403 3.31621 28.1222L2.44391 28.4601C2.09644 28.5947 2.09644 29.0747 2.44391 29.2092L3.31621 29.5471C4.56039 30.029 5.54569 30.9906 6.0395 32.2049L6.38571 33.0562C6.52362 33.3953 7.01543 33.3953 7.15334 33.0562L7.49955 32.2049C7.99336 30.9906 8.97866 30.029 10.2228 29.5471L11.0951 29.2093C11.4426 29.0747 11.4426 28.5947 11.0951 28.4601L10.2228 28.1222C8.97866 27.6403 7.99337 26.6787 7.49955 25.4645L7.15335 24.6132Z"
          fill="white"
        />
        <path
          d="M21.8721 20.0209C21.7342 19.6818 21.2424 19.6818 21.1045 20.0209L20.7583 20.8722C20.2644 22.0864 19.2791 23.048 18.035 23.5299L17.1627 23.8678C16.8152 24.0024 16.8152 24.4824 17.1627 24.617L18.035 24.9548C19.2791 25.4368 20.2644 26.3983 20.7583 27.6126L21.1045 28.4639C21.2424 28.803 21.7342 28.803 21.8721 28.4639L22.2183 27.6126C22.7121 26.3983 23.6974 25.4368 24.9416 24.9548L25.8139 24.617C26.1614 24.4824 26.1614 24.0024 25.8139 23.8678L24.9416 23.5299C23.6974 23.048 22.7121 22.0864 22.2183 20.8722L21.8721 20.0209Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

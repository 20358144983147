export const Cross = ({ crossColour = "white" }: { crossColour: string }) => {
  return (
    <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.84882 5.1514C6.38019 4.68277 5.62039 4.68277 5.15176 5.1514C4.68314 5.62003 4.68314 6.37983 5.15176 6.84845L10.3032 11.9999L5.15176 17.1514C4.68314 17.62 4.68314 18.3798 5.15176 18.8485C5.62039 19.3171 6.38019 19.3171 6.84882 18.8485L12.0003 13.697L17.1518 18.8485C17.6204 19.3171 18.3802 19.3171 18.8488 18.8485C19.3174 18.3798 19.3174 17.62 18.8488 17.1514L13.6973 11.9999L18.8488 6.84845C19.3174 6.37983 19.3174 5.62003 18.8488 5.1514C18.3802 4.68277 17.6204 4.68277 17.1518 5.1514L12.0003 10.3029L6.84882 5.1514Z"
      fill={crossColour}
      fillOpacity="0.8"
    />
  </svg>
  );
}
import { useNavigate } from "react-router-dom";
import { MovingSpinningCircles } from "../../components/Icons";
import { TypewriterText } from "../../components";
import { useEffect, useState } from "react";
import classnames from "classnames";

export default function Initial() {
  const navigate = useNavigate();

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.origin !== "https://infographics.devsjr.com") {
        return;
      }
      if (event.data === "OPEN") {
        navigate("/streaming");
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [navigate]);

  const [expanded, setExpanded] = useState(false);
  const [textIsDone, setTextIsDone] = useState(false);

  return (
    <div
      className={classnames("w-full h-full flex", {
        "bg-black/50 backdrop-blur-md transition-all duration-1000 absolute bottom-0 ":
          expanded,
      })}
    >
      <div
        className={classnames(
          "flex fixed bottom-0 content-center items-center opacity-80 z-50",
          {
            "transition-all duration-1000 justify-center bg-[#000000] text-white h-[70%] desktop:w-[calc(100%-100px)] inset-x-0 desktop:px-[122px] desktop:py-[85px] desktop:mx-[50px] flex-col p-[5%] rounded-t-[50px]":
              expanded,
            "left-1/2 -translate-x-1/2  mobile:mb-[49px] desktop:mb-[30px] tablet:mb-[44px]  bg-[#000000] text-white h-[150px] max-w-[848px] p-4 backdrop-blur-[20px] w-[375px] desktop:w-[636px] desktop:h-[72px] rounded-[42px]":
              !expanded,
          }
        )}
        onClick={() => {
          setExpanded(true);
          setTimeout(() => {
            navigate("/streaming");
          }, 1000);
        }}
      >
        <div
          className={classnames("flex ml-[5px] mt-[20px] justify-center", {
            block: !expanded,
            hidden: expanded,
          })}
        >
          <MovingSpinningCircles />
        </div>
        <TypewriterText
          id="initial"
          isDone={textIsDone}
          setIsDone={setTextIsDone}
          className={classnames("text-base", {
            block: !expanded,
            hidden: expanded,
          })}
          text="Hello! I'm a conversational AI. How can I help you today?"
        />
      </div>
    </div>
  );
}

import { LongInform, XThingsInform, ShortInform } from "../../templates/Inform";
import {
  LongInspire,
  ShortInspire,
  XThingsInspire,
} from "../../templates/Inspire";
import { TemplateSelect } from "../../components";
import { templateArray } from "../../templates/templateList";
import { Ref, useEffect, useState } from "react";
import { ShortHero, XThingsHero, LongHero } from "../../templates/Hero";
import { Single } from "../../templates/Single/Single";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import mockQuestionResponse from "../../context/mock-data";

const MainApp = () => {
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [templatesInView, setTemplatesInView] = useState<string[]>([]);
  const formFieldName = "Select Templates ";
  const selectorOnChange = (selectedArray: string[]) => {
    setSelectedTemplates(selectedArray);
  };
  const selectorPrompt = "Select Templates...";
  const [nextTemplate, setNextTemplate] = useState<Ref<HTMLDivElement>>(null);

  const { ref: shortInspireRef, inView: shortInspireInView } = useInView({
    threshold: 0.5,
  });
  const { ref: longInspireRef, inView: longInspireInView } = useInView({
    threshold: 0.5,
  });
  const { ref: xInspireRef, inView: xInspireInView } = useInView({
    threshold: 0.5,
  });

  const { ref: shortHeroRef, inView: shortHeroInView } = useInView({
    threshold: 0.5,
  });
  const { ref: longHeroRef, inView: longHeroInView } = useInView({
    threshold: 0.5,
  });
  const { ref: xHeroRef, inView: xHeroInView } = useInView({
    threshold: 0.5,
  });

  const { ref: longInformRef, inView: longInformInView } = useInView({
    threshold: 0.5,
  });

  const { ref: shortInformRef, inView: shortInformInView } = useInView({
    threshold: 0.5,
  });

  const { ref: xInformRef, inView: xInformInView } = useInView({
    threshold: 0.5,
  });

  const { ref: singleRef, inView: singleInView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    setTemplatesInView([
      shortInspireInView ? "ShortInspire" : "",
      longInspireInView ? "LongInspire" : "",
      xInspireInView ? "XThingsInspire" : "",
      shortInformInView ? "ShortInform" : "",
      longInformInView ? "LongInform" : "",
      xInformInView ? "XThingsInform" : "",
      shortHeroInView ? "ShortHero" : "",
      longHeroInView ? "LongHero" : "",
      xHeroInView ? "XThingsHero" : "",
      singleInView ? "Single" : "",
    ]);
  }, [
    shortInspireInView,
    longInspireInView,
    xInspireInView,
    shortInformInView,
    longInformInView,
    xInformInView,
    shortHeroInView,
    singleInView,
  ]);

  const testingTemplate = (template: string, index: number) => {
    if (template === "ShortInspire")
      return (
        <ShortInspire
          ref={shortInspireRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.ShortInspire}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}          
        />
      );
    if (template === "LongInspire")
      return (
        <LongInspire
          ref={longInspireRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.LongInspire}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}  
        />
      );
    if (template === "XThingsInspire")
      return (
        <XThingsInspire
          ref={xInspireRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.XThingsInspire}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}  
        />
      );

    if (template === "ShortInform")
      return (
        <ShortInform
          ref={shortInformRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.ShortInform}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}  
        />
      );
    if (template === "LongInform")
      return (
        <LongInform
          ref={longInformRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.LongInform}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}  
        />
      );
    if (template === "XThingsInform")
      return (
        <XThingsInform
          ref={xInformRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.XThingsInform}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}  
        />
      );

    if (template === "ShortHero")
      return (
        <ShortHero
          ref={shortHeroRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.ShortHero}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}  
        />
      );
    if (template === "LongHero")
      return (
        <LongHero
          ref={longHeroRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.LongHero}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}  
        />
      );
    if (template === "XThingsHero")
      return (
        <XThingsHero
          ref={xHeroRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.XThingsHero}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}  
        />
      );
    if (template === "Single")
      return (
        <Single
          ref={singleRef}
          templateOrderIndex={index}
          loading={false}
          key={index}
          askQuestion={() => {}}
          response={mockQuestionResponse.Single}
          setNextTemplate={setNextTemplate}      
          nextTemplate={nextTemplate}  
        />
      );
  };

  return (
    <div className="h-full w-full block font-inter relative">
      <div className="mobile:hidden tablet:hidden desktop:block fixed top-0 left-0 z-50">
        {selectedTemplates.map((template, index) => {
          return (
            <div
              key={index}
              className={classNames("px-4 py-1 m-2 rounded-2xl", {
                ["bg-background-brand text-white border-2 border-white"]:
                  templatesInView.includes(template),
                ["bg-transparent text-black"]:
                  !templatesInView.includes(template),
              })}
            >
              {index + 1}
            </div>
          );
        })}
      </div>
      <TemplateSelect
        formFieldName={formFieldName}
        prompt={selectorPrompt}
        onChange={selectorOnChange}
        options={templateArray}
      />
      {selectedTemplates[0] &&
        selectedTemplates.map((template: string, index: number) => {
          return testingTemplate(template, index);
        })}
    </div>
  );
};

export default MainApp;

import StreamedJsonToObjectTransformer from "../utils/stream-transformer";
import { ChatHistory, Chunk } from "../interface";

const stream = async ({
  chat_history,
  conversation_id,
  user_id,
  query,
  seen_images,
  is_selected_follow_up,
  onChunk,
  onDone,
}: {
  chat_history: ChatHistory;
  conversation_id: string;
  user_id: string;
  query: string;
  seen_images: string[];
  is_selected_follow_up: boolean;
  onChunk: (chunk: Chunk) => void;
  onDone: () => void;
}) => {
  const streamedJsonToObjectStream = new TransformStream(
    new StreamedJsonToObjectTransformer()
  );
  const response = await fetch(
    "https://api.uat.nonprod.audiencebrains.satalia.io/chat/generate?client_id=wpp&site_id=wpp_com&stream=true",
    {
      method: "POST",
      headers: {
        "Api-Key": "57dcea54-a3c6-4303-8215-11e457dc77a0",
        "Content-Type": "application/json",
        responseType: "stream",
      },
      body: JSON.stringify({
        chat_history,
        conversation_id,
        is_selected_follow_up,
        user_id,
        query,
        seen_images,
      }),
    }
  );
  const rs = response.body;
  if (rs) {
    const jsonStream = rs.pipeThrough(streamedJsonToObjectStream);
    const reader: ReadableStreamDefaultReader = jsonStream.getReader();

    while (true) {
      const { done, value } = await reader?.read();
      if (!done) {
        onChunk((value as any).chunk as Chunk);
      }
      if (done) {
        onDone();
        return;
      }
    }
  }
};

export default stream;

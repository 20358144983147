import classNames from "classnames";
import { AssetsData } from "../../interface";
import { Video, PDF } from "../Icons";

export default function MediaLinks({ assets }: { assets: AssetsData }) {
  const videos =
    assets?.video
      .filter((item) => item.relevance_score > 1)
      .sort((a, b) => b.relevance_score - a.relevance_score) || [];

  const pdfs =
    assets?.pdf
      .filter((item) => item.relevance_score > 1)
      .sort((a, b) => b.relevance_score - a.relevance_score) || [];

  if (!videos.length && !pdfs.length) {
    return null;
  }

  return (
    <div className={classNames(videos.length || pdfs.length ? "mt-6" : "")}>
      {videos.length > 0 && (
        <a
          href={videos[0].url}
          target="_blank"
          className="flex pt-3 font-bold text-sm items-center"
        >
          <Video />
          <span className="ml-2">
            You might be interested in our video
            {videos[0].metadata?.name && (
              <span>
                {" '"}
                <span className="underline">{videos[0].metadata.name}</span>'
              </span>
            )}
          </span>
        </a>
      )}
      {pdfs.length > 0 && (
        <a
          href={pdfs[0].url}
          target="_blank"
          className="flex pt-3 font-bold text-sm items-center"
        >
          <PDF />
          <span className="ml-2">
            You might be interested in our{" "}
            {videos[0].metadata?.name && (
              <span>
                <span className="underline">{videos[0].metadata.name}</span>'
              </span>
            )}
            PDF
          </span>
        </a>
      )}
    </div>
  );
}

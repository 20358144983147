import classnames from "classnames";
export const Button = ({
  style,
  classes,
  onClick,
  text,
}: {
  style: "primary" | "secondary" | "tertiary" | "brand";
  classes: string;
  onClick?: () => void;
  text: string;
}) => {
  const primary = classnames(
    "bg-white mobile:text-sm text-grey-1000 border border-grey-700 hover:bg-grey-400 mobile:px-[1.375rem] mobile:py-[0.75rem] tablet:px-[2rem] tablet:py-[0.875rem]",
    classes
  );

  const secondary = classnames(
    "bg-white/50 mobile:text-sm text-grey-1000 border border-grey-400 hover:bg-grey-400 mobile:px-[1.375rem] mobile:py-[0.75rem] tablet:px-[2rem] tablet:py-[0.875rem]",
    classes
  );

  const tertiary = classnames(
    "bg-grey-900 mobile:text-sm text-grey-400 border border-grey-700 hover:bg-grey-1000/50 mobile:px-[1.375rem] mobile:py-[0.75rem] tablet:px-[2rem] tablet:py-[0.875rem]",
    classes
  );

  const brand = classnames(
    "bg-primary-400 mobile:text-sm text-white border border-grey-700 hover:bg-primary-700 mobile:px-[1.375rem] mobile:py-[0.75rem] tablet:px-[2rem] tablet:py-[0.875rem]",
    classes
  );
  return (
    <button
        id="follow-up-button"
        type="button"
        className={classnames(
          style === "primary" && primary,
          style === "secondary" && secondary,
          style === "tertiary" && tertiary,
          style === "brand" && brand,
          classes,
          "rounded-3xl",
        )}
        onClick={onClick}
      >
        {text}
      </button>
  )
}
export const Video = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4988_904)">
        <path
          d="M11.1 11.3415V14.899C11.1 15.2863 11.5147 15.5323 11.8546 15.3465L15.1092 13.5676C15.4631 13.3741 15.463 12.8659 15.1091 12.6725L11.8546 10.894C11.5147 10.7082 11.1 10.9542 11.1 11.3415ZM8.21 7C6.98945 7 6 7.98945 6 9.21V17.03C6 18.2506 6.98945 19.24 8.21 19.24H17.39C18.6105 19.24 19.6 18.2506 19.6 17.03V9.21C19.6 7.98945 18.6105 7 17.39 7H8.21ZM7.02 9.21C7.02 8.55278 7.55278 8.02 8.21 8.02H17.39C18.0472 8.02 18.58 8.55278 18.58 9.21V17.03C18.58 17.6872 18.0472 18.22 17.39 18.22H8.21C7.55278 18.22 7.02 17.6872 7.02 17.03V9.21Z"
          fill="currentColor"
        />
        <circle cx="13" cy="13" r="12.65" stroke="currentColor" stroke-width="0.7" />
      </g>
      <defs>
        <clipPath id="clip0_4988_904">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Grid = ({
  children,
  customClasses,
}: {
  children: React.ReactNode;
  customClasses: string;
}) => {
  return (
    <div
      id="grid"
      className={`grid mobile:grid-cols-8 tablet:grid-cols-12 desktop:grid-cols-24 z-40 ${customClasses} desktop:mx-[2.5%]`}
    >
      {children}
    </div>
  );
};


